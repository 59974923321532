import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@iq/react-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import JSONEditor from '../../../../JSONEditor';
import SimpleModal from '../../../../SimpleModal';
import { getTimeSeriesExportSchema } from '../../schemas/variableSchema';
import {
  fetchExtendedSeriesData,
  stopFetchExtendedSeriesData,
} from '../../../../../bundles/variables';
import { getSiteVariables, getSourceConnectivityTree } from '../../../../../bundles/sources';
import {
  useDateSubscription,
  setPollingDateRange,
  getPollingDateRange,
} from '../../../../../bundles/application';
import {
  EXPORT_TIMESERIES_DATA_POINTS_LIMIT_EC,
  EXPORT_TIMESERIES_PANEL,
} from '../../../../../constants';
import { getActiveSite } from '../../../../../bundles/sites';
import ExportProgress from '../../../../ExportProgress';

import { exportTimeSeriesToFile, capitalize } from '../../../../../utils';

const ExportTimeSeriesModal = (props) => {
  const {
    onCloseModal,
    selectedVariables,
    selectedVizVariables,
    exportGranularity = '60s',
    showAggregation = true,
    onExportCompleted,
    parentPanelId,
  } = props;
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [seriesData, setSeriesData] = useState([]);
  const [estDataPoints, setEstDataPoints] = useState(0);
  const [showValidationError, setShowValidationError] = useState(false);

  const vizPanelDateRange = parentPanelId
    ? useSelector((state) => getPollingDateRange(state, parentPanelId))
    : {};
  const inputDateRange = useDateSubscription(EXPORT_TIMESERIES_PANEL);
  useEffect(() => {
    if (Object.keys(vizPanelDateRange).length > 0)
      dispatch(setPollingDateRange({ ...vizPanelDateRange }, EXPORT_TIMESERIES_PANEL));
  }, [vizPanelDateRange]);

  const [schema, uiSchema] = getTimeSeriesExportSchema(
    showAggregation,
    exportGranularity,
    parentPanelId || EXPORT_TIMESERIES_PANEL
  );
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const site = useSelector(getActiveSite);
  const siteVariables = useSelector(getSiteVariables);
  const connectivityTree = useSelector(getSourceConnectivityTree);

  const variables =
    selectedVariables ||
    selectedVizVariables.map((v) => {
      const variable = siteVariables.find(({ id }) => id === v.id);
      return {
        ...v,
        name: variable.name,
        source: variable.source,
        itemDesignation: variable.itemDesignation,
      };
    });

  const errorMessage =
    'There are too many data points in the selected range to export. Please consider changing aggregation granularity or export a smaller chunk of data every time.';

  const exportDataValidation = (data) => {
    if (!data.granularity) {
      return data;
    }

    setSubmitDisabled(false);
    const estimatedDataPoints = Math.round(
      inputDateRange.duration / (1000 * parseInt(data.granularity.split('s')[0], 10))
    );

    setEstDataPoints(estimatedDataPoints);
    delete inputDateRange.granularity;
    const submitData = {
      ...data,
      ...inputDateRange,
    };
    if (estimatedDataPoints > EXPORT_TIMESERIES_DATA_POINTS_LIMIT_EC) {
      setShowValidationError(true);
      setSubmitDisabled(true);
    } else {
      setShowValidationError(false);
    }

    return submitData;
  };

  useEffect(() => {
    setFormData(
      exportDataValidation(
        inputDateRange
          ? {
              startDate: inputDateRange.startDate,
              endDate: inputDateRange.endDate,
              granularity: exportGranularity,
              ...formData,
            }
          : formData
      )
    );
  }, [inputDateRange]);

  const onChange = ({ formData: updatedFormData }) => {
    setFormData(exportDataValidation(updatedFormData));
  };

  const onCancelExport = () => {
    dispatch(stopFetchExtendedSeriesData());
    setShowProgressModal(false);
  };

  const onVariablesExport = useCallback((form) => {
    setShowProgressModal(true);
    dispatch(
      fetchExtendedSeriesData({ ...form, variables, connection: connectivityTree }, (data) => {
        if (data.hasError) {
          onCloseModal();
        }
        setSeriesData(data?.length === 0 ? [{ values: [] }] : data);
      })
    );
  }, []);

  const handleProgressCompleted = useCallback(() => {
    exportTimeSeriesToFile({
      ...formData,
      siteName: site.name,
      seriesData,
      variables,
      onExportCompleted,
    });
  }, [formData, variables, seriesData, onExportCompleted, site]);

  return (
    <div
      onDoubleClick={(e) => {
        e.stopPropagation();
      }}
    >
      {showProgressModal ? (
        <SimpleModal
          onClose={onCloseModal}
          title="Export data"
        >
          <div className="export-progress-modal">
            <ExportProgress
              retrievedDataPoints={seriesData.length}
              expectedDataPoints={estDataPoints}
              variables={variables}
              onExportCompleted={handleProgressCompleted}
            />
            <label className="selected-signals-label">
              {`Selected Signals (${variables?.length})`}
            </label>
            <div className="export-progress-content custom-scrollbar">
              {variables.map((variable, index) => (
                <div key={`${variable.id}-${index}`}>
                  {`${variable.label || variable.name}`}
                  {variable.itemDesignation && `${variable.itemDesignation}`}
                  {variable.aggregate ? ` (${capitalize(variable.aggregate)})` : ''}
                </div>
              ))}
            </div>
            <Button
              className="cancel"
              activity="secondary"
              onClick={onCancelExport}
            >
              Cancel
            </Button>
          </div>
        </SimpleModal>
      ) : (
        <SimpleModal
          onClose={onCloseModal}
          title="Export data"
        >
          <div className="export-variables-modal">
            <div className="export-variables-content">
              <JSONEditor
                formData={formData}
                schema={schema}
                uiSchema={uiSchema}
                submitDisabled={submitDisabled}
                onFormChange={onChange}
                onFormSubmit={({ formData: form }) => onVariablesExport(form)}
                saveButtonText={'Export'}
                initialEditMode={true}
                showEditButton={false}
                customErrorMessage={showValidationError && errorMessage}
                cancelCallback={onCloseModal}
              />
            </div>
          </div>
        </SimpleModal>
      )}
    </div>
  );
};

export default ExportTimeSeriesModal;
