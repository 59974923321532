/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Input, Spinner } from '@iq/react-components';

import CustomSelect from '../../CustomSelect';
import ListItem from '../../ListItem';
import EditItemModal from '../../EditItemModal';
import { getVariableSchema, variableUiSchema } from './schemas/variableSchema';
import {
  requestSources,
  getSources,
  getSourceTypes,
  getSourcesLoaded,
  getExtendedSiteVariables,
  requestExtendedSiteVariables,
  createVariable,
  updateVariable,
  deleteVariable,
  getExtendedSiteVariablesUpdatedAt,
} from '../../../bundles/sources';
import { getActiveSite } from '../../../bundles/sites';
import { getStateSets, requestStateSets, getStateSetsLoaded } from '../../../bundles/statesets';
import { camelToSentence } from '../../../utils';
import Heading from '../../Heading';
import ExportTimeSeriesModal from './components/ExportTimeSeriesModal';

const VariableView = ({ wizardType }) => {
  const dispatch = useDispatch();
  const { id: siteId } = useSelector(getActiveSite);
  const variablesLoaded = useSelector(getExtendedSiteVariablesUpdatedAt);
  const sources = useSelector(getSources);
  const sourcesLoaded = useSelector(getSourcesLoaded);
  const sourceTypes = useSelector(getSourceTypes);
  const variables = useSelector(getExtendedSiteVariables);
  const statesets = useSelector(getStateSets);
  const statesetsLoaded = useSelector(getStateSetsLoaded);

  const [vizFilter, setVizFilter] = useState('all');
  const [searchFilter, setSearchFilter] = useState('');
  const [sortBy, setSortBy] = useState({ sortKey: 'name', desc: true });
  const [varSchema, setVarSchema] = useState(null);
  const [createVarSchema, setCreateVarSchema] = useState({});
  const [updateVarUiSchema, setUpdateVarUiSchema] = useState({});
  const [createVarData, setCreateVarData] = useState({});
  const [sortedVars, setSortedVars] = useState([]);
  const [showCreateVariableForm, setShowCreateVariableForm] = useState(false);
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [showDownloadForm, setShowDownloadForm] = useState(false);
  const [isFilterChanged, setIsFilterChanged] = useState(false);

  useEffect(() => {
    if (siteId && !sourcesLoaded) {
      dispatch(requestSources({ siteId }));
      dispatch(requestStateSets(siteId));
    }
  }, [siteId, sourcesLoaded]);

  useEffect(() => {
    if (sourcesLoaded && !variablesLoaded) {
      dispatch(requestExtendedSiteVariables());
    }
  }, [sourcesLoaded]);

  const filterSelected = (value) => {
    setIsFilterChanged(true);
    setVizFilter(value);
  };

  const filteredVars = useMemo(() => {
    if (variables && vizFilter) {
      return variables.filter((v) => {
        if (vizFilter === 'noViz') return !(v.visualizations || []).length;
        if (vizFilter === 'onlyViz') return (v.visualizations || []).length > 0;
        return v;
      });
    }
    return [];
  }, [variables, vizFilter]);

  const searchedVars = useMemo(() => {
    if (searchFilter) {
      const filters = searchFilter.trim().split(' ');
      return filteredVars.filter((v) =>
        filters.every((f) => v.name.toLowerCase().indexOf(f.toLowerCase()) !== -1)
      );
    }
    return filteredVars;
  }, [filteredVars, searchFilter]);

  const nameSort = (values, attr, descending = false, shouldReturn = false) => {
    let nameSorted = [];
    if (!shouldReturn) {
      nameSorted = descending
        ? values.sort((a, b) => a.name.localeCompare(b.name))
        : values.sort((a, b) => b.name.localeCompare(a.name));
    }

    if (attr === 'name') {
      return nameSorted;
    }
    if (attr === 'itemDesignation') {
      if (shouldReturn) {
        return descending
          ? values.sort((a, b) => (a.itemDesignation || '').localeCompare(b.itemDesignation || ''))
          : values.sort((a, b) => (b.itemDesignation || '').localeCompare(a.itemDesignation || ''));
      }
      return nameSort(nameSorted, 'itemDesignation', descending, true);
    }
    if (attr === 'source') {
      if (shouldReturn) {
        return descending
          ? values.sort((a, b) => a.source.name.localeCompare(b.source.name))
          : values.sort((a, b) => b.source.name.localeCompare(a.source.name));
      }
      return nameSort(nameSorted, 'source', descending, true);
    }
    if (attr === 'stateset') {
      if (shouldReturn) {
        return descending
          ? values.sort((a, b) =>
              ((a.stateset?.name || '-') + a.name).localeCompare((b.stateset?.name || '-') + a.name)
            )
          : values.sort((a, b) =>
              ((b.stateset?.name || '-') + a.name).localeCompare((a.stateset?.name || '-') + a.name)
            );
      }
      return nameSort(nameSorted, 'stateset', descending, true);
    }
    return values;
  };

  useEffect(() => {
    if (sortBy.sortKey) {
      const sortVal = [...nameSort(searchedVars, sortBy.sortKey, sortBy.desc)];
      const selectedIds = selectedVariables.map((a) => a.id);
      const newSelectedVariables = [];
      sortVal.forEach((element) => {
        element.isSelected = false;
        if (selectedIds.includes(element.id)) {
          element.isSelected = true;
          newSelectedVariables.push(element);
        }
      });
      setSortedVars(() => sortVal);
      if (isFilterChanged) {
        setSelectedVariables(newSelectedVariables);
        setIsFilterChanged(false);
      }
    }
  }, [sortBy, searchedVars]);

  const count = sortedVars.length ? `(${sortedVars.length})` : '';
  const vizFilterOptions = [
    { value: 'all', label: 'All' },
    { value: 'noViz', label: 'No visualizations' },
    { value: 'onlyViz', label: 'With visualizations' },
  ];

  const getSourceOptionsSchema = (source, sourceType) => ({
    properties: {
      source_id: { enum: [source.id] },
      options: {
        type: 'object',
        title: 'Options',
        description: 'Additional identifiers and settings. Generally these should not be changed.',
        required: sourceType.schemas.variable.properties.options.required,
        properties: {
          ...Object.entries(sourceType.schemas.variable.properties.options.properties).reduce(
            (acc, [k, v]) => {
              acc[k] = { ...v, title: camelToSentence(k) };
              return acc;
            },
            {}
          ),
        },
      },
    },
  });

  const variableSourceOptions = useMemo(
    () =>
      sources.map((source) => {
        const sourceType = sourceTypes.find((st) => st.name === source.type);
        if (
          Object.keys(sourceType.schemas.variable?.properties?.options?.properties || {}).length
        ) {
          return getSourceOptionsSchema(source, sourceType);
        }
        return {
          properties: {
            source_id: { enum: [source.id] },
          },
        };
      }),
    [sources, sourceTypes]
  );

  // don't include statesets if in wizard
  const variableSchema = useMemo(() => {
    const states = wizardType ? null : (statesetsLoaded && statesets) || null;
    return getVariableSchema(states);
  }, [statesets, statesetsLoaded, wizardType]);

  useEffect(() => {
    if (sources.length) {
      setVarSchema({
        ...variableSchema,
        required: ['name', 'source_id'],
        properties: {
          ...variableSchema.properties,
          id: { type: 'string' },
          source_id: {
            ...variableSchema.properties.source_id,
            default: sources[0].id,
            oneOf: sources.map((source) => ({
              const: source.id,
              title: source.name,
            })),
          },
        },
        dependencies: {
          source_id: {
            oneOf: variableSourceOptions,
          },
        },
      });
    }
  }, [variableSchema, sources, variableSourceOptions]);

  useEffect(() => {
    setUpdateVarUiSchema({
      ...variableUiSchema,
      source_id: { 'ui:widget': 'hidden' },
    });
  }, [variableUiSchema]);

  useEffect(() => {
    if (sources.length) {
      setCreateVarSchema({
        ...variableSchema,
        required: ['name', 'source_id'],
        properties: {
          ...variableSchema.properties,
          source_id: {
            ...variableSchema.properties.source_id,
            default: sources[0].id,
            oneOf: sources.map((source) => ({
              const: source.id,
              title: source.name,
            })),
          },
        },
        dependencies: {
          source_id: {
            oneOf: variableSourceOptions,
          },
        },
      });
    }
  }, [variableSchema, sources, variableSourceOptions]);

  const prepUpdate = (rawData) => {
    const selectedVar = JSON.parse(rawData.options.variableSelect);
    return {
      ...rawData,
      name: rawData.name || selectedVar.name,
      itemDesignation: rawData.itemDesignation || selectedVar.itemDesignation,
      dataType: rawData.dataType || selectedVar.dataType,
      unit: rawData.unit || selectedVar.unit,
      options: {
        ...rawData.options,
        objectId: selectedVar.objectId,
        variableId: selectedVar.variableId,
        itemDesignation: selectedVar.itemDesignation,
      },
    };
  };

  const onUpdateVariable = (sourceId, updatedVariable) => {
    const data = { ...updatedVariable };
    if (!data.dataType) {
      data.dataType = null;
    }
    if (!data.unit) {
      data.unit = null;
    }
    dispatch(updateVariable(sourceId, data.id, data));
  };

  const onCreateVariable = ({ formData: { source_id: sourceId, ...createData } }) => {
    dispatch(createVariable(sourceId, createData));
    setShowCreateVariableForm(false);
    setCreateVarData({});
  };

  const onChangeCreateVariable = ({ formData: newVariable }) => {
    if (
      newVariable.options?.variableSelect &&
      newVariable.options.variableSelect !== createVarData.options.variableSelect
    ) {
      setCreateVarData(() => prepUpdate(newVariable));
    } else {
      setCreateVarData(() => newVariable);
    }
  };

  const handleClose = useCallback(
    () => setShowCreateVariableForm(false),
    [setShowCreateVariableForm]
  );
  const handleCloseExportModal = useCallback(
    () => setShowDownloadForm(false),
    [setShowDownloadForm]
  );
  const handleExportCompleted = useCallback(() => {
    handleClose();
    setSelectedVariables([]);
    sortedVars.map((v) => (v.isSelected = false));
    setSortedVars(() => [...sortedVars]);
  });

  const onDeleteVariable = useCallback((sourceId, variableId) => {
    dispatch(deleteVariable(sourceId, variableId));
  }, []);

  const onSelectVariable = (variable) => {
    const index = sortedVars.findIndex((s) => s.id === variable.id);
    sortedVars[index].isSelected = !sortedVars[index].isSelected;
    setSortedVars(() => [...sortedVars]);
    if (selectedVariables.find((f) => f.id === variable.id)) {
      setSelectedVariables(selectedVariables.filter((f) => f.id !== variable.id));
    } else {
      setSelectedVariables([...selectedVariables, variable]);
    }
  };

  const toggleSelectAllVariables = (variablesToSelect) => {
    if (selectedVariables.length === variablesToSelect.length) {
      setSelectedVariables([]);
      sortedVars.map((v) => (v.isSelected = false));
      setSortedVars(() => [...sortedVars]);
    } else {
      setSelectedVariables(variablesToSelect);
      sortedVars.map((v) => (v.isSelected = true));
      setSortedVars(() => [...sortedVars]);
    }
  };

  const handleSort = (sortKey) => {
    const desc = sortBy.sortKey === sortKey ? !sortBy.desc : true;
    setSortBy(() => ({ sortKey, desc }));
  };

  let headers = [
    {}, // For select column
    { sortKey: 'name', label: 'Signal name' },
    { sortKey: 'itemDesignation', label: 'Item designation' },
    { sortKey: 'source', label: 'Data source' },
  ];

  if (!wizardType) {
    headers = [
      ...headers,
      { sortKey: 'stateset', label: 'Linked state set' },
      { sortKey: undefined, label: 'Linked components' },
    ];
  }

  const headerColumns = headers.map((h) => {
    if (!h.sortKey) {
      return <div>{h.label}</div>;
    }
    const icon = sortBy.sortKey === h.sortKey && !sortBy.desc ? 'abb-caret-up' : 'abb-caret-down';
    return (
      <Button
        onClick={() => handleSort(h.sortKey)}
        icon={<Icon icon={icon} />}
        iconPosition="right"
        activity="secondary"
      >
        {h.label}
      </Button>
    );
  });

  const getListItemColumns = (variable) => {
    const cols = [
      <div className="table-cell select-column">
        <Button design="text">
          <Icon
            className={`checkbox ${variable.isSelected ? 'checked' : ''}`}
            icon={`${variable.isSelected ? 'check-box' : 'check-box-outline-blank'}`}
            size="s"
            onClick={(e) => {
              e.stopPropagation();
              onSelectVariable(variable);
            }}
          />
        </Button>
      </div>,
      <>
        {variable.visualization?.length ? (
          <Icon
            icon={'abb-trend-2'}
            size="s"
          />
        ) : null}
        <div className="ellipsed-text">{variable.name}</div>
      </>,
      <div className="ellipsed-text">{variable.itemDesignation}</div>,
      <div className="ellipsed-text">{variable.source.name}</div>,
      <div className="ellipsed-text">{`${variable.stateset?.name || ''}`}</div>,
      <div className="blocked-item-container">
        {variable.components.map((c, i) => (
          <div
            key={`comp-${i}`}
            className="blocked-item"
          >
            {`${c.name} (${c.itemDesignation})`}
          </div>
        ))}
      </div>,
    ];

    // don't include statesets if in wizard
    if (wizardType) {
      return cols.slice(0, 2);
    }
    return cols;
  };

  const compareValuesOfSelected = (a, b) => {
    return a.every((val, index) => val === b[index]);
  };

  const allSelected =
    sortedVars &&
    selectedVariables &&
    sortedVars.length &&
    sortedVars.length === selectedVariables.length &&
    compareValuesOfSelected(
      selectedVariables.map((a) => a.id),
      sortedVars.map((b) => b.id)
    );

  const signalList = useMemo(() => {
    if (!variablesLoaded) {
      return (
        <div className="loading-container">
          <Spinner
            size="m"
            className="spinner"
          />
        </div>
      );
    }
    return (
      <div className="manage-variables-body custom-thin-scrollbar">
        <p className="signals">{`Manage signals ${count}`} </p>

        <div className="handle-selected-files">
          <label className="select-all-label">
            <Icon
              className={`select-all ${allSelected ? 'checked' : ''}`}
              icon={`${allSelected ? 'check-box' : 'check-box-outline-blank'}`}
              size="s"
              onClick={(e) => {
                e.stopPropagation();
                toggleSelectAllVariables(sortedVars);
              }}
            />
            Select all
          </label>
          {selectedVariables.length > 0 && (
            <Button
              onClick={() => setShowDownloadForm(true)}
              activity="secondary"
              icon={<Icon icon="abb-download" />}
              tooltip="Download selected"
              slim={true}
            />
          )}
        </div>
        <div className="list-variable-container manage-variables-body custom-thin-scrollbar">
          <ListItem
            isHeader
            columns={headerColumns}
            columnWidths={{ type: 'rem', widths: [1.5, 14, 14, 14, 14, 'fill'] }}
          />
          {sortedVars.map((v) => (
            <ListItem
              key={v.id}
              entity={`Signal (${v.name})`}
              item={v}
              columns={getListItemColumns(v)}
              columnWidths={{ type: 'rem', widths: [1.5, 14, 14, 14, 14, 'fill'] }}
              onSubmit={(update) => onUpdateVariable(v.source_id, update)}
              onDelete={() => onDeleteVariable(v.source_id, v.id)}
              confirmationDialogTitle={'Delete signal'}
              confimationDialogBody={
                <>
                  <p style={{ paddingTop: '1.5rem' }}>
                    Are you sure you want to remove this signal?
                  </p>
                </>
              }
              schema={varSchema}
              uiSchema={updateVarUiSchema}
            />
          ))}
        </div>
      </div>
    );
  }, [sortedVars, varSchema, variablesLoaded]);

  const wizClass = wizardType ? `--${wizardType}` : '';

  if (!variablesLoaded) {
    return (
      <div className="loading-container">
        <Spinner
          size="m"
          className="spinner"
        />
      </div>
    );
  }

  return (
    <>
      <Heading
        contentLeft={
          <div className="signals-header">
            <div className="title">Signals</div>
            <Input
              type="text"
              onChange={(e) => setSearchFilter(e.target.value)}
              value={searchFilter}
              placeholder="Search signal name"
            />

            <div className="signal-select">
              <CustomSelect
                isMulti={false}
                onChange={(selection) => filterSelected(selection)}
                creatable={false}
                value={vizFilter}
                rawOptions={vizFilterOptions}
                closeMenuOnSelect={true}
                styles={{
                  control: (base) => ({
                    ...base,
                    minWidth: '100%',
                    height: '36px',
                    minHeight: '36px',
                  }),
                }}
              />
            </div>
          </div>
        }
        contentRight={
          <Button
            onClick={() => setShowCreateVariableForm(true)}
            className={`button-create${wizardType ? wizClass : ''}`}
            icon={<Icon icon="abb-plus" />}
          >
            Add Signal
          </Button>
        }
      />
      {signalList}
      {showCreateVariableForm && (
        <EditItemModal
          entity="Signal"
          formData={createVarData}
          schema={createVarSchema}
          uiSchema={variableUiSchema}
          onChange={onChangeCreateVariable}
          onSubmit={onCreateVariable}
          onCloseModal={handleClose}
        />
      )}
      {showDownloadForm && selectedVariables?.length > 0 && (
        <ExportTimeSeriesModal
          selectedVariables={selectedVariables}
          onCloseModal={handleCloseExportModal}
          onExportCompleted={handleExportCompleted}
        />
      )}
    </>
  );
};

export default VariableView;
