import React from 'react';
import { Icon } from '@iq/react-components';

import { toNumDecimals, getHexaDecimal, hexToRGB } from '../../../utils';
import { utcToSite, datetimeString } from '../../../datetimeUtils';
import { STATUSES, PAGE_TYPES } from '../../../constants';
import { units } from '../../../units';
/*
 * render/highlight/overlay/show reference: [
 *   0: None (default), 1: All, 2: Active, 3: Active else/or all
 * ]
 */
function shouldRenderComponent({
  isActive,
  hasActive,
  viewerClickedId,
  renderComponents,
  focusActiveComponent,
  isolate,
}) {
  if (focusActiveComponent && isActive && !viewerClickedId) {
    return true;
  }
  if (focusActiveComponent && hasActive && !isActive) {
    return false;
  }
  if (renderComponents === 1 && (!focusActiveComponent || !hasActive || viewerClickedId)) {
    return true;
  }
  if (renderComponents === 2 && isActive) {
    return true;
  }
  if (renderComponents === 3 && (isActive || (!isActive && !hasActive))) {
    return true;
  }
  return !isolate;
}

function shouldHighlightComponent({
  isActive,
  hasActive,
  viewerClickedId,
  highlightComponents,
  focusActiveComponent,
}) {
  if (focusActiveComponent && isActive && !viewerClickedId) {
    return false;
  }
  if (highlightComponents === 1 && (!focusActiveComponent || !hasActive || viewerClickedId)) {
    return true;
  }
  if (highlightComponents === 2 && isActive) {
    return true;
  }
  return highlightComponents === 3 && (isActive || (!isActive && !hasActive));
}

function getComponentOverlay(
  component,
  { isActive, hasActive, viewerClickedId, overlayComponents, focusActiveComponent },
  pageType
) {
  if ((!overlayComponents && !isActive) || pageType === PAGE_TYPES.none.toLowerCase()) {
    return null;
  }
  const ownStatus = component.ownStatus && component.ownStatus.status;
  const filteredStatus = component.filteredStatus && component.filteredStatus.status;

  const healthStatus = ownStatus >= 0 ? ownStatus : filteredStatus;
  const riskStatus =
    component.filteredStatus?.eventRiskStatus?.sort(
      (x, y) => y.eventDateTime - x.eventDateTime
    )[0] || {};

  const getOverlayColor = () => {
    const colorObj = {
      risk: riskStatus && STATUSES[riskStatus.status]?.colorHex,
      stateset:
        component.state?.color &&
        (component.state?.quality === 1 || component.state?.area) &&
        getHexaDecimal(component.state.color),
      health: STATUSES[healthStatus]?.colorHex,
    };
    return colorObj[pageType] || null;
  };

  const color = getOverlayColor();
  if (color || color === 0) {
    if (overlayComponents && focusActiveComponent && isActive && !viewerClickedId) {
      return color;
    }
    if (overlayComponents === 1 && (!focusActiveComponent || !hasActive || viewerClickedId)) {
      return color;
    }
    if (overlayComponents === 2 && isActive) {
      return color;
    }
    if (overlayComponents === 3 && (isActive || !hasActive)) {
      return color;
    }
  }
  return null;
}

function shouldShowPin(
  component,
  pushPin,
  { isActive, hasActive, viewerClickedId, showPushPins, displayErrorPins, focusActiveComponent }
) {
  const hasError = component.ownStatus && component.ownStatus.status > 0;
  if (displayErrorPins && hasError) {
    return true;
  }
  if (!pushPin) {
    return false;
  }
  if (focusActiveComponent && isActive && !viewerClickedId) {
    return true;
  }
  if (showPushPins === 1 && (!focusActiveComponent || !hasActive || viewerClickedId)) {
    return true;
  }
  if (showPushPins === 2 && isActive) {
    return true;
  }
  return showPushPins === 3 && (isActive || (!isActive && !hasActive));
}

function getPinStyle(component, pageType) {
  const riskStatus =
    component.filteredStatus?.eventRiskStatus?.sort(
      (x, y) => y.eventDateTime - x.eventDateTime
    )[0] || {};

  const healthStatus = component.filteredStatus?.status > 0 && component.filteredStatus.status;

  const getPinColor = () => {
    const colorObj = {
      none: STATUSES.default.color,
      risk: riskStatus && STATUSES[riskStatus.status]?.color,
      stateset:
        component.state?.color &&
        (component.state?.quality === 1 || component.state?.area) &&
        hexToRGB(component.state.color),
      health: STATUSES[healthStatus]?.color,
    };
    return colorObj[pageType] || colorObj.none;
  };

  const color = getPinColor();

  return {
    backgroundColor: `rgba(${color}, 0.4)`,
    border: `1px solid rgb(${color})`,
  };
}

function getPushpinTooltipContent(comp, pushPin, pageType, timezone, height) {
  const content = [];
  const riskStatus =
    comp.filteredStatus?.eventRiskStatus?.sort((x, y) => y.eventDateTime - x.eventDateTime)[0] ||
    {};
  const healthStatus = comp.filteredStatus;

  const getPinContent = () => {
    switch (pageType) {
      case 'risk':
        return {
          status: riskStatus?.status,
          statusText: riskStatus?.mitigation && riskStatus.eventText,
          riskMitigation: riskStatus?.mitigation,
          risks: () => {
            const riskValue = Object.entries(riskStatus)
              .filter(([k]) => k.includes('fr') || k.includes('mttr'))
              .map(([k, v]) => {
                let time = '';
                const value = v.toFixed(2);
                const prop = k.toLowerCase();
                if (prop.includes('day')) {
                  time = '2 days';
                } else if (prop.includes('week')) {
                  time = '1 week';
                } else if (prop.includes('month')) {
                  time = '1 month';
                }
                return {
                  time: value,
                  timeUnit: k.includes('fr') ? 'h' : '',
                  timespan: time,
                  type: k.includes('fr') ? 'fr' : 'mttr',
                };
              });
            const AvailabilityRisk = riskValue.filter((x) => x.type === 'fr');
            const ShutdownRisk = riskValue.filter((x) => x.type === 'mttr');
            const risk = {};
            risk['Availability Risk'] = AvailabilityRisk;
            risk['Shutdown Risk'] = ShutdownRisk;
            return risk;
          },
        };

      case 'health':
        return {
          status: healthStatus?.status || 0,
          statusText: healthStatus?.statusText,
          pushpins: pushPin.variables,
        };

      default:
        return {
          pushpins: pushPin.variables,
        };
    }
  };

  const { status, statusText, pushpins, risks, riskMitigation } = getPinContent();

  const { color, icon } = STATUSES[status] || {};
  if (pushpins) {
    pushpins.forEach(
      ({
        variableDefaultUnit,
        name,
        value: val,
        quality,
        timestamp = Date.now(),
        unit,
        maxDecimals = 3,
      }) => {
        let unitLabel;
        let value = '-';
        if (!Number.isNaN(parseInt(val, 10))) {
          const formattedValue = toNumDecimals(val, maxDecimals);
          value = formattedValue;
          if (unit === 'variable-default') {
            unitLabel = units.find((u) => u.id === variableDefaultUnit)
              ? units.find((u) => u.id === variableDefaultUnit).unit(value, maxDecimals)
              : variableDefaultUnit;
          } else {
            unitLabel = units.find((u) => u.id === unit)
              ? units.find((u) => u.id === unit).unit(val, maxDecimals)
              : undefined;
          }
        }

        content.push({
          title: name,
          value,
          dt: datetimeString(utcToSite(timestamp, timezone), timezone),
          unit: unitLabel,
          quality,
        });
      }
    );
  }
  return (
    <div className="pushpin-tooltip--wrapper">
      <div className="pushpin-tooltip--header">
        <div className="pushpin-tooltip--description">
          <div title={comp.name}>{comp.name}</div>
          <div title={comp.itemDesignation}>{comp.itemDesignation}</div>
        </div>
        <div className="pushpin-tooltip--icon">
          <Icon icon={comp.icon} />
        </div>
      </div>
      {status && status !== 0 && (
        <div
          className="pushpin-tooltip--status"
          style={{ backgroundColor: `rgba(${color}, 0.85)` }}
        >
          <div className="pushpin-tooltip--status--icon">
            <Icon icon={icon} />
          </div>
          <div className="pushpin-tooltip--status--text">{statusText}</div>
        </div>
      )}
      {content.length > 0 && (
        <div className="pushpin-tooltip--values">
          {content.map(({ title, value, dt, unit, quality }, i) => (
            <div
              key={value + i}
              className={`value-wrapper ${quality !== 1 ? 'invalid-data' : ''}`}
            >
              <div
                className="title"
                title={title}
              >
                {title}
                {quality !== 1 ? (
                  <Icon
                    icon="abb-help-circle-2"
                    size="m"
                    color="#FF7300"
                  />
                ) : null}
              </div>
              <div className="value">
                {value}
                <span className="unit">{unit}</span>
              </div>
              {value === '-' ? null : <div className="datetime">{dt}</div>}
            </div>
          ))}
        </div>
      )}
      {riskMitigation && (
        <>
          <div className="pushpin-tooltip--values">
            <div className="pushpin-tooltip--values">
              {Object.keys(risks).map((key, j) => {
                const riskTime = risks[key];
                return (
                  <div
                    key={j}
                    className="value-wrapper"
                  >
                    <div className="suggestion-availability">{key}</div>
                    {riskTime.map((value, i) => (
                      <div
                        key={i + 1}
                        className="suggestion-availability"
                      >{`${value.time} ${value.timeUnit} in ${value.timespan}`}</div>
                    ))}
                  </div>
                );
              })}
            </div>
            <div className="suggestion-description">
              <div className="value-wrapper">
                <br />
                1st mitigation recommendation
              </div>
              <div
                style={{ maxHeight: height > 250 ? height - 240 : '100%' }} // Min height of the viewer panel in 240px
                className="description-text"
              >
                {riskMitigation}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

// eslint-disable-next-line
function getComponentsTooltipContent(tooltip, timezone) {
  const content = [];

  content.push({
    title: tooltip.displayName,
    value: tooltip.displayValue,
    message: tooltip.freeText,
    stateText: tooltip.stateText,
    stateDescription: tooltip.stateDescription,
  });

  if (tooltip && tooltip.variables) {
    tooltip.variables.forEach(
      ({ name, value, timestamp = Date.now(), unit, variableDefaultUnit, maxDecimals = 3 }) => {
        let valueText = '-';

        if (!Number.isNaN(parseInt(value, 10))) {
          const formattedValue = toNumDecimals(value, maxDecimals);
          let unitLabel;
          if (unit === 'variable-default') {
            unitLabel = units.find((u) => u.id === variableDefaultUnit)
              ? units.find((u) => u.id === variableDefaultUnit).unit(value, maxDecimals)
              : variableDefaultUnit;
          } else {
            unitLabel = units.find((u) => u.id === unit)
              ? units.find((u) => u.id === unit).unit(value, maxDecimals)
              : undefined;
          }
          valueText = `${formattedValue} ${unitLabel || ''}`;
        }

        content.push({
          title: name,
          value: valueText,
          dt: datetimeString(utcToSite(timestamp, timezone), timezone),
        });
      }
    );
  }

  return content.map(({ title, value, dt, message, stateText, stateDescription }) => (
    <div key={title + dt}>
      <div>
        <div className="value-wrapper">
          <div
            className="title"
            title={title}
          >
            {title}
          </div>
          <div className="value">{value}</div>
          {value === '-' ? null : <div className="datetime">{dt}</div>}
        </div>
        {message ? <div className="message">{message}</div> : null}
      </div>
      {stateText ? (
        <div>
          <div className="value">{stateText}</div>
          {stateText !== stateDescription ? <div className="value">{stateDescription}</div> : null}
        </div>
      ) : null}
    </div>
  ));
}

export {
  shouldRenderComponent,
  shouldHighlightComponent,
  getComponentOverlay,
  shouldShowPin,
  getPinStyle,
  getPushpinTooltipContent,
  getComponentsTooltipContent,
};
