import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { Button, Icon, Spinner } from '@iq/react-components';

import {
  downloadComponentsExcel,
  uploadComponentsExcel,
  setImportErrors,
  getImportErrors,
  getComponentsImportedAt,
  resetImportedComponentsAt,
} from '../../../../../../../bundles/components';
import Heading from '../../../../../../Heading';
import ConfirmationDialog from '../../../../../../ConfirmationDialog';
import InstructionalModal from '../../../../../../InstructionalModal';

const ImportStep = ({ site, onNext }) => {
  const dispatch = useDispatch();
  const compsImported = useSelector(getComponentsImportedAt);
  const importErrors = useSelector(getImportErrors);
  const fullScreenRef = useRef(null);

  const [importing, setImporting] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [showInstruction, setShowInstruction] = useState(false);

  const onDropAccepted = ([file]) => {
    setImporting(true);
    dispatch(uploadComponentsExcel(file, site.id, site.org));
  };

  const onDropRejected = () => {
    dispatch(setImportErrors(['Imports should be from a single Excel file.']));
  };

  const { getInputProps, getRootProps } = useDropzone({
    disabled: importing,
    multiple: false,
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onDropAccepted,
    onDropRejected,
  });

  useEffect(() => {
    if (importing && compsImported) {
      setImporting(false);
      dispatch(resetImportedComponentsAt());
      onNext();
    }
  }, [importing, compsImported]);

  useEffect(() => {
    if (importErrors.length) {
      setImporting(false);
      setShowErrors(true);
    } else {
      setShowErrors(false);
    }
  }, [importErrors]);

  return (
    <>
      <div className="component-import-step">
        <Heading
          contentLeft={<div className="title">Import components from an Excel</div>}
          large
        />
        <div className="component-import-step__button-row">
          <Button
            onClick={() => dispatch(downloadComponentsExcel(site))}
            activity="secondary"
            design="text"
            icon={
              <div className="icon-green">
                <Icon icon="abb-document-excel" />
              </div>
            }
          >
            Export component template
          </Button>
          <div className="component-import-step__info-trigger">
            <Icon icon="abb-help-circle-1" />
            Excel info
          </div>
          <div className="component-import-step__info-tooltip">
            <p>The downloaded excel will contain all of the components for the site.</p>
            <p>When updating the excel you can edit, remove or add new components.</p>
          </div>
        </div>
        <div
          className="component-import-step__drop-zone"
          {...getRootProps()}
        >
          <input
            name="files"
            {...getInputProps()}
          />
          {importing ? (
            <Spinner />
          ) : (
            <div>
              <Icon
                icon="abb-upload"
                size="l"
              />
              <p>{'Drag & drop components excel here, or click to select files'}</p>
            </div>
          )}
        </div>
        <Button
          onClick={() => setShowInstruction(true)}
          activity="secondary"
          design="text"
          icon={
            <div className="icon-wiz-color">
              <Icon icon="abb-information-circle-1" />
            </div>
          }
        >
          How to prepare a component excel
        </Button>
        {showInstruction && (
          <InstructionalModal
            fullScreenRef={fullScreenRef}
            onClose={() => setShowInstruction(false)}
            identifier="wiz-comp-import"
            title="How to prepare a component excel"
          />
        )}
      </div>
      {showErrors ? (
        <ConfirmationDialog
          onConfirm={() => dispatch(setImportErrors([]))}
          onCancel={() => dispatch(setImportErrors([]))}
          showCancel={false}
          title="Import errors"
          subtitle="The following errors occurred while attempting import:"
          body={importErrors.map((e, i) => (
            <p key={`err-${i}`}>{e}</p>
          ))}
          className="setup-wizard-errors"
        />
      ) : null}
    </>
  );
};

export default ImportStep;
