import React, { useEffect, useMemo, useCallback } from 'react';
import { Button, Icon } from '@iq/react-components';

import { formatBytes } from '../../../../../utils';
import CustomSelect from '../../../../CustomSelect';
import ComponentSelect from '../../../../ComponentSelect';
import TagSelect from '../../../../TagSelect';
import FiletypeIcon from '../../../../FiletypeIcon';

const FileDetail = ({
  file: { file, components, type, tags, errors, warnings },
  activeComponentId,
  availableComponents,
  availableTags,
  availableTypes,
  limitTags,
  onClickRemove,
  onCopyData,
  onSetProperty,
  files,
}) => {
  const onSetComponents = useCallback(
    (value) => onSetProperty('components', value),
    [onSetProperty]
  );

  const onSetTags = useCallback((value) => onSetProperty('tags', value), [onSetProperty]);

  useEffect(() => {
    if (activeComponentId) {
      onSetProperty('components', [activeComponentId]);
    }
  }, []);

  const onStopLabelClicks = useCallback((e) => e.preventDefault(), []);

  const onCopyAllTags = useCallback(
    (e) => {
      if (e.isDefaultPrevented()) {
        return;
      }
      onCopyData({ tags });
    },
    [tags, files]
  );

  const onCopyAllComponents = useCallback(
    (e) => {
      if (e.isDefaultPrevented()) {
        return;
      }
      onCopyData({ components });
    },
    [components, files]
  );

  const onCopyAllType = useCallback(
    (e) => {
      if (e.isDefaultPrevented()) {
        return;
      }
      onCopyData({ type });
    },
    [type, files]
  );

  const presetTags = useMemo(() => {
    if (tags) {
      const presets = [...tags];
      if (limitTags && limitTags.includesTogether) {
        limitTags.includesTogether.forEach((id) => {
          if (!presets.includes(id)) {
            presets.push(id);
          }
        });
      }
      return presets;
    }
    return [];
  }, [tags, limitTags]);

  return (
    <div className="file-detail">
      <div>
        <div className="file-info">
          <div className="file-info-row">
            <div className="filetype">
              {
                <FiletypeIcon
                  file={{ fileType: file.type, filename: file.name, mime: file.type }}
                />
              }
            </div>
            <div className="filename">{file.name}</div>
            <div className="filesize">{`(${formatBytes(file.size)})`}</div>
            {errors?.name && <div className="error-text">{errors.name}</div>}
          </div>

          <div className="button-row">
            {onClickRemove && (
              <Button
                onClick={onClickRemove}
                activity="secondary"
                icon={<Icon icon="abb-trash" />}
                tooltip="Remove from list"
              />
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="column">
          <label onClick={onStopLabelClicks}>Type</label>

          <div className="row">
            <div className="select-container">
              <CustomSelect
                isMulti={false}
                placeholder="Select type"
                value={type}
                onChange={(value) => onSetProperty('type', value)}
                rawOptions={availableTypes.map((t) => ({ label: t, value: t }))}
              />
            </div>
            {onCopyData && (
              <Button
                className="copy-all"
                onClick={onCopyAllType}
                activity="secondary"
                icon={<Icon icon="abb-copy" />}
                tooltip="Copy Types to all files in list"
              />
            )}
          </div>

          {errors && errors.type && <div className="error-text">{errors.type}</div>}
          {warnings && warnings.type && <div className="error-text">{warnings.type}</div>}
        </div>

        <div className="column">
          <label onClick={onStopLabelClicks}>Tags</label>

          <div className="row">
            <div className="select-container">
              <TagSelect
                onChange={onSetTags}
                value={presetTags}
                availableTags={availableTags}
                limitTags={limitTags}
              />
            </div>
            {onCopyData && (
              <Button
                className="copy-all"
                onClick={onCopyAllTags}
                activity="secondary"
                icon={<Icon icon="abb-copy" />}
                tooltip="Copy Tags to all files in list"
              />
            )}
          </div>

          {errors && errors.tags && <div className="error-text">{errors.tags}</div>}
          {warnings && warnings.tags && <div className="error-text">{warnings.tags}</div>}
        </div>
      </div>

      <div className="column">
        <label onClick={onStopLabelClicks}>Components</label>

        <div className="row">
          <div className="select-container">
            <ComponentSelect
              value={components}
              availableComponents={availableComponents}
              onChange={onSetComponents}
            />
          </div>
          {onCopyData && (
            <Button
              className="copy-all"
              onClick={onCopyAllComponents}
              activity="secondary"
              icon={<Icon icon="abb-copy" />}
              tooltip="Copy Components to all files in list"
            />
          )}
        </div>

        {errors && errors.components && <div className="error-text">{errors.components}</div>}
      </div>
    </div>
  );
};

export default FileDetail;
