import React, { useState, useEffect } from 'react';
import { Icon, Input } from '@iq/react-components';

const SiteFilter = ({ onChange }) => {
  const [filter, setFilter] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => onChange(filter), 650);
    return () => clearTimeout(timer);
  }, [filter]);

  const updateValue = ({ target: { value } }) => {
    setFilter(value);
  };

  const resetValue = () => {
    setFilter('');
    onChange('');
  };

  return (
    <div className="site-filter-component">
      <Input
        value={filter}
        placeholder="Filter sites ..."
        onChange={updateValue}
      />
      <Icon
        size="s"
        icon="close"
        onClick={resetValue}
      />
    </div>
  );
};

export default SiteFilter;
