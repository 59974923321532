import React, { useState, useEffect, useMemo, useCallback, useLayoutEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@iq/react-components';

import { capitalize } from '../../../../../utils';
import EditItemModal from '../../../../EditItemModal';
import FormListItem from '../../../../FormListItem';
import JSONEditor from '../../../../JSONEditor';
import Heading from '../../../../Heading';
import SimpleModal from '../../../../SimpleModal';
import { getComponentSchema, componentUiSchema as uiSchema } from '../../schemas/componentSchema';
import propertySchema from '../../schemas/propertySchema';
import { updateComponent, getComponents } from '../../../../../bundles/components';
import { getSources, getSourceTypes, getSiteVariables } from '../../../../../bundles/sources';
import { getStateSets } from '../../../../../bundles/statesets';
import { getThumbnails, requestModelThumbnail } from '../../../../../bundles/sites';

const ComponentModal = (props) => {
  const {
    modelsHashmap,
    onSubmit,
    onCloseModal,
    saveButtonText = 'Save',
    updateId, // if defined, we are updating
    parentId, // if defined, we are creating
  } = props;
  const dispatch = useDispatch();

  const [schema, setSchema] = useState(null);
  const [showCreatePropertyForm, setShowCreatePropertyForm] = useState(false);

  const sources = useSelector(getSources);
  const sourceTypes = useSelector(getSourceTypes);
  const stateSets = useSelector(getStateSets);
  const components = useSelector(getComponents);
  const variables = useSelector(getSiteVariables);
  const thumbnails = useSelector(getThumbnails);

  const models = useMemo(() => Object.values(modelsHashmap), [JSON.stringify(modelsHashmap)]);

  useEffect(() => {
    models.forEach((model) => {
      model.versions.forEach((version) => dispatch(requestModelThumbnail(model.id, version.id)));
    });
  }, [models]);

  const { properties, ...component } = useMemo(() => {
    const comp = { ...(components.find((c) => c.id === updateId) || {}) };
    if (comp.source_id) {
      comp.source = comp.source_id;
      delete comp.source_id;
    }
    if (comp.options) {
      comp.sourceOptions = comp.options;
      delete comp.options;
    }
    if (comp.variables) {
      comp.variables = comp.variables.map((v) => v.id);
    }
    return comp;
  }, [JSON.stringify(components), updateId]);

  const [compData, setCompData] = useState(parentId ? { parent: parentId } : component);
  const [propertyData, setPropertyData] = useState(properties || []);

  useLayoutEffect(() => {}, []);

  const byName = ({ name: aName }, { name: bName }) => {
    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    setPropertyData((properties || []).sort(byName));
  }, [JSON.stringify(properties)]);

  const statefulVariables = useMemo(() => variables.filter((v) => v.stateset_id), [variables]);

  useEffect(() => {
    setSchema(
      getComponentSchema({
        compData,
        components,
        models,
        sources,
        sourceTypes,
        stateSets,
        statefulVariables,
        thumbnails,
      })
    );
  }, [
    compData,
    components,
    models,
    sources,
    sourceTypes,
    stateSets,
    statefulVariables,
    thumbnails,
  ]);

  // If we upgrade to RJSFv5, we can try to get rid of the custom validation.
  const customValidate = useCallback(
    (formData, errors) => {
      if (formData.itemDesignation) {
        components.forEach((comp) => {
          if (
            comp.id !== formData.id &&
            comp.itemDesignation.toLowerCase() === formData.itemDesignation.toLowerCase()
          ) {
            errors.itemDesignation.addError('Item designation should be unique.');
          }
        });
      }

      schema.required.forEach((fieldName) => {
        const validValue = formData[fieldName] || formData[fieldName] === 0;

        if (!validValue) {
          if (fieldName === 'itemDesignation') {
            errors[fieldName].addError('Item designation is required');
          } else {
            errors[fieldName].addError(`${capitalize(fieldName)} is required`);
          }
        }
      });
      return errors;
    },
    [compData, components, schema]
  );

  const onComponentChange = ({ formData: updateCompData }) => {
    setCompData(updateCompData);
  };

  // This is added to handle a duplicate error message for itemDesignations.
  const transformErrors = (errors) =>
    errors.map((error) => {
      /* eslint-disable no-return-assign, no-param-reassign */
      if (error.name === 'required') {
        if (error.property === 'itemDesignation' || error.property === 'name') {
          error.message = '';
        }
      }
      return error;
    });

  const onComponentUpdate = () => {
    const compUpdate = { ...compData };
    if (compUpdate.sourceConfig && !compUpdate.sourceConfig.objectId) {
      delete compUpdate.sourceConfig;
    }
    onSubmit(compUpdate);
  };

  // Properties
  const onCreateProperty = ({ formData: newProperty }) => {
    dispatch(
      updateComponent(compData.id, compData.site, {
        properties: [newProperty, ...(propertyData || [])],
      })
    );
    setShowCreatePropertyForm(false);
  };

  const onUpdateProperty = (updatedProperty, propertyIndex) => {
    dispatch(
      updateComponent(compData.id, compData.site, {
        properties: [
          ...propertyData.slice(0, propertyIndex),
          updatedProperty,
          ...propertyData.slice(propertyIndex + 1),
        ],
      })
    );
  };

  const onDeleteProperty = (propertyIndex) => {
    dispatch(
      updateComponent(compData.id, compData.site, {
        properties: [
          ...propertyData.slice(0, propertyIndex),
          ...propertyData.slice(propertyIndex + 1),
        ],
      })
    );
  };

  const onCloseCreateProperty = () => {
    setShowCreatePropertyForm(false);
  };

  const [propertyFormData, setPropertyFormData] = useState({
    attribute: '',
    name: '',
    value: '',
    category: '',
  });

  const onPropertyChange = ({ formData: updatedFormData }) => {
    setPropertyFormData(updatedFormData);
  };

  // If we upgrade to RJSFv5, we can try to get rid of the custom validation.
  const validateProperty = useCallback(
    (fData, errors) => {
      propertySchema.required.forEach((fieldName) => {
        const validValue = fData[fieldName] || fData[fieldName] === 0;
        if (!validValue) {
          errors[fieldName].addError(`${capitalize(fieldName)} is required`);
        }
      });
      return errors;
    },
    [propertySchema]
  );

  const modalTitle = parentId ? 'Create component' : 'Edit component';
  const componentProperties = propertyData.length ? (
    propertyData.map((property, propertyIndex) => (
      <FormListItem
        entity="Property"
        formData={property}
        key={`${property.name}-${propertyIndex}`}
        itemIndex={propertyIndex}
        onDelete={onDeleteProperty}
        onSubmit={onUpdateProperty}
        schema={propertySchema}
        title={property.name}
        customValidate={validateProperty}
        editorOnly
      />
    ))
  ) : (
    <div>No properties yet, create one to get started</div>
  );

  return schema ? (
    <SimpleModal
      title={modalTitle}
      onClose={onCloseModal}
    >
      <div className="column-content">
        <JSONEditor
          title={compData.name}
          schema={schema}
          uiSchema={uiSchema}
          formData={compData}
          onFormChange={onComponentChange}
          onFormSubmit={onComponentUpdate}
          cancelCallback={onCloseModal}
          saveButtonText={saveButtonText}
          ignoreKeys={['variables']}
          initialEditMode
          showEditButton={false}
          customValidate={customValidate}
          customTransformErrors={(errors) => transformErrors(errors)}
        />
      </div>

      {compData.type !== 'virtual' && !parentId && (
        <div className="column-content">
          <Heading
            contentLeft={<div className="title">Properties</div>}
            contentRight={
              <Button
                activity="primary"
                slim
                onClick={() => setShowCreatePropertyForm(true)}
              >
                Add
              </Button>
            }
          />

          {componentProperties}
        </div>
      )}

      {showCreatePropertyForm && (
        <EditItemModal
          entity="Property"
          schema={propertySchema}
          onSubmit={onCreateProperty}
          onCloseModal={onCloseCreateProperty}
          customValidate={validateProperty}
          onChange={onPropertyChange}
          formData={propertyFormData}
        />
      )}
    </SimpleModal>
  ) : null;
};

ComponentModal.propTypes = {
  modelsHashmap: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  saveButtonText: PropTypes.string,
  updateId: PropTypes.string,
  parentId: PropTypes.string,
};

export default ComponentModal;
