import React, { useEffect, useCallback, useMemo, memo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Button, Spinner } from '@iq/react-components';
import { DEFAULT_AGGREGATION_OPTIONS } from '../../constants';

import Tabs, { Tab } from '../Tabs/Tabs';
import Visualization, {
  schema,
  uiSchema,
  visualizations as visualizationTypes,
} from '../Visualization';
import JSONEditor from '../JSONEditor';
import BigModal from '../BigModal';
import Heading from '../Heading';
import InlineEditor from '../InlineEditor';

import { getStaticComponents } from '../../bundles/components';
import {
  getVisualizations,
  upsertVisualization,
  getSingleVisualization,
  saveVisualization,
  LAST_VALUE_VISUALIZATIONS,
} from '../../bundles/visualizations';
import {
  getSources,
  getSourceTypes,
  getSiteVariables,
  getSourcesLoaded,
} from '../../bundles/sources';
import {
  useDateSubscription,
  getRangeFromLabel,
  getRangeFromPartial,
} from '../../bundles/application';

import { units } from '../../units';
import colors from '../../colors';
import { getVariableSchema, updateVisualizationSchema } from './functions';

const VisualizationEditor = memo(
  ({ onClose, visualizationId, visualizationNames, parentPanelId }) => {
    const { id: siteId } = useParams();
    const dispatch = useDispatch();
    const stateComponents = useSelector(getStaticComponents);
    const visualizations = useSelector(getVisualizations);
    const visualization = visualizations.find((v) => v.id === visualizationId);
    const sources = useSelector(getSources);
    const sourcesLoaded = useSelector(getSourcesLoaded);
    const sourceTypes = useSelector(getSourceTypes);
    const siteVariables = useSelector(getSiteVariables);
    const [isTemplate, setIsTemplate] = useState(!!visualization?.isTemplate);
    const [variableSchema, setVariableSchema] = useState(null);
    const [valid, setValid] = useState(false);

    const panelId = 'visualizationEditor';
    const pollingDateRange = useDateSubscription(panelId);

    const sortedSiteVars = useMemo(
      () =>
        siteVariables.toSorted((a, b) => {
          const { source_id: idA, name: nameA, itemDesignation: desA } = a;
          const { source_id: idB, name: nameB, itemDesignation: desB } = b;
          const sourceA = (sources.find((s) => s.id === idA) || {}).name || '';
          const sourceB = (sources.find((s) => s.id === idB) || {}).name || '';
          return (sourceB + desB + nameB).localeCompare(sourceA + desA + nameA);
        }),
      [siteVariables]
    );

    const components = useMemo(() => stateComponents, [JSON.stringify(stateComponents)]);

    useEffect(() => {
      setValid(visualization.variables.length > 0 || isTemplate);
    }, [visualization, isTemplate]);

    /* When variable form is updated or changed. */
    const onVariableForm = useCallback(
      ({ formData: signals }) => {
        let saveable = false;
        signals.forEach((signal) => {
          if (!signal.aggregate && signal.aggregate !== null) {
            saveable = false;
          }
        });
        setValid(saveable);
        dispatch(upsertVisualization(siteId, { id: visualization.id, variables: signals }));

        dispatch(
          getSingleVisualization(
            {
              ...visualization,
              variables: signals,
            },
            panelId
          )
        );
      },
      [siteId]
    );

    const onComponentForm = useCallback(
      (data, refresh = false) => {
        let { formData: relations } = data;
        relations = relations.map((r) => ({ ...r, entity_type: 'components' }));
        dispatch(
          upsertVisualization(siteId, {
            id: visualization.id,
            relations,
          })
        );

        if (refresh) {
          const relativeAdjustedRange = pollingDateRange?.label
            ? getRangeFromLabel(pollingDateRange.label)
            : getRangeFromPartial(pollingDateRange);
          dispatch(getSingleVisualization(visualization, panelId, relativeAdjustedRange));
        }
      },
      [siteId]
    );

    const updatedSchema = useMemo(
      () =>
        updateVisualizationSchema({
          visualization,
          siteVariables,
          schema,
          colors,
        }),
      [schema, siteVariables, visualization, colors]
    );

    const typedSources = useMemo(
      () =>
        sources.map((source) => {
          const sType = sourceTypes.find((t) => t.name === source.type);
          if (sType) {
            const { aggregationOptions, aggregationOptionNames, aggregationDefault } = sType;
            return {
              ...source,
              aggregationOptions,
              aggregationOptionNames,
              aggregationDefault,
            };
          }
          return source;
        }),
      [sources, sourceTypes]
    );

    const variableSources = useMemo(
      () =>
        sortedSiteVars.reduce(
          (acc, v) => {
            const vSourceIndex = acc.findIndex((source) => source.id === v.source_id);
            if (vSourceIndex >= 0) {
              acc[vSourceIndex].variables = [...(acc[vSourceIndex].variables || []), v.id];
            }
            return acc;
          },
          [...typedSources]
        ),
      [typedSources, sortedSiteVars]
    );

    const { type: vizType } = visualization;

    useEffect(() => {
      if (sortedSiteVars.length && variableSources.length) {
        const varSchema = getVariableSchema({
          sortedSiteVars,
          units,
          variableSources,
          DEFAULT_AGGREGATION_OPTIONS,
        });

        if (LAST_VALUE_VISUALIZATIONS.includes(vizType)) {
          delete varSchema.items.dependencies;
          delete varSchema.items.properties.unit;
          delete varSchema.items.properties.decimals;
        }
        setVariableSchema(() => varSchema);
      } else if (sourcesLoaded) {
        setVariableSchema(() => {});
      }
    }, [sourcesLoaded, sortedSiteVars, variableSources, units, vizType]);

    const onConfigurationFormChange = useCallback(
      (data) => {
        const { formData: configuration } = data;

        dispatch(
          upsertVisualization(siteId, {
            id: visualization.id,
            configuration,
          })
        );

        dispatch(getSingleVisualization({ ...visualization, configuration }, panelId));
      },
      [siteId, visualization]
    );

    const componentRelationsSchema = useMemo(
      () => ({
        type: 'array',
        title: 'Relations',
        isMulti: true,
        items: {
          type: 'object',
          properties: {
            entity_id: {
              type: 'string',
              title: 'Component',
              placeholder: 'Select a component',
              anyOf: components.map((comp) => ({
                const: comp.id,
                title: `${comp.name} (${comp.itemDesignation})`,
              })),
            },
          },
        },
      }),
      [components]
    );
    const variableUiSchema = {
      items: {
        label: ['metric', 'health', 'gauge'].includes(visualization.type)
          ? { 'ui:classNames': 'hidden' }
          : {},
      },
    };

    const onTypeChange = (type) => {
      dispatch(
        upsertVisualization(siteId, {
          id: visualization.id,
          type,
        })
      );
    };

    const handleSaveVisualization = () => {
      const { relations, ...viz } = visualization;

      dispatch(
        saveVisualization(
          {
            ...viz,
            isTemplate,
            relations: relations.filter((r) => r.entity_id),
          },
          parentPanelId
        )
      );

      onClose();
    };

    const visualizationTypeSelectors = visualizationTypes.map(({ type, icon, name }) => (
      <div
        key={type}
        className={`visualization-type ${type === visualization.type ? 'selected' : ''}`}
        onClick={() => onTypeChange(type)}
      >
        <Icon icon={icon} />
        <div className="type">{name}</div>
      </div>
    ));

    let variablePanel = (
      <div className="visualization-config--loading">
        <Spinner />
      </div>
    );

    if (sourcesLoaded) {
      variablePanel = Object.keys(variableSchema || {}).length ? (
        <JSONEditor
          showButtons={false}
          showEditButton={false}
          initialEditMode={true}
          formData={visualization.variables}
          schema={variableSchema}
          uiSchema={variableUiSchema}
          cancelCallback={onClose}
          onFormChange={onVariableForm}
          onFormSubmit={onVariableForm}
        />
      ) : (
        <div className="visualization-config--no-variables">
          {'No signals found.\nYou may need to add a source or import signals.'}
        </div>
      );
    }

    return (
      <BigModal onClose={onClose}>
        <div className="visualizationeditor-component">
          <div className="visualization-header">
            <div className="name">
              <InlineEditor
                value={visualization.name}
                onSave={(value) =>
                  dispatch(
                    upsertVisualization(siteId, {
                      id: visualization.id,
                      name: value,
                    })
                  )
                }
                uniqueItems={visualizationNames}
                uniqueError="Visualization name should be unique."
              />
            </div>
            <Button
              design="text"
              onClick={onClose}
              icon={<Icon icon="abb-close" />}
            />
          </div>

          <div className="visualization-preview">
            <Visualization
              visualization={visualization}
              isPreview={true}
              panelId={panelId}
              pollingDateRange={pollingDateRange}
            />
          </div>

          <div className="visualization-config">
            <Tabs
              actions={
                <div className="reset-save">
                  <Button
                    activity="secondary"
                    type="text"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    activity="primary"
                    onClick={handleSaveVisualization}
                    disabled={!valid}
                  >
                    Save
                  </Button>
                </div>
              }
            >
              <Tab
                id="variables"
                title="Signals"
              >
                <div className="wrapper custom-scrollbar">{variablePanel}</div>
              </Tab>

              <Tab
                id="config"
                title="Visualization"
              >
                <div className="wrapper custom-scrollbar">
                  <div className="visualization-type-selector-component">
                    {visualizationTypeSelectors}
                  </div>
                  <div>
                    <Heading contentLeft={<div className="title">Use as template</div>} />
                    <Icon
                      className={`is-template-checkbox ${isTemplate ? 'on' : ''}`}
                      icon={isTemplate ? 'toggle-on' : 'toggle-off'}
                      onClick={() => setIsTemplate(!isTemplate)}
                      size="l"
                    />
                  </div>
                  <JSONEditor
                    title="Configuration"
                    scrollIntoView={false}
                    context={visualization}
                    initialEditMode={true}
                    showEditButton={false}
                    showButtons={false}
                    schema={updatedSchema}
                    uiSchema={uiSchema(visualization.type)}
                    formData={visualization.configuration}
                    cancelCallback={onClose}
                    onFormChange={onConfigurationFormChange}
                  />
                </div>
              </Tab>

              <Tab
                id="relations"
                title="Relations"
              >
                <div className="wrapper custom-scrollbar">
                  <JSONEditor
                    initialEditMode={true}
                    showEditButton={false}
                    showButtons={false}
                    schema={componentRelationsSchema}
                    cancelCallback={onClose}
                    formData={visualization.relations}
                    onFormChange={onComponentForm}
                    onFormSubmit={(data) => onComponentForm(data, true)}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </BigModal>
    );
  }
);

export default VisualizationEditor;
