import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon } from '@iq/react-components';

import {
  requestModelThumbnail,
  getThumbnails,
  deleteSite,
  getSites,
  getSitesLoaded,
} from '../../bundles/sites';
import { getIsTenantOrSuperAdmin } from '../../bundles/auth';
import { clearSiteData } from '../../bundles/application';

import Map from '../Map';
import AppContext from '../../AppContext';
import ConfirmationDialog from '../ConfirmationDialog';
import { DropdownMenu } from '../DropdownMenu';
import Notifications from '../Notifications';
import SiteThumbnail from '../SiteThumbnail';
import SystemMessages from '../SystemMessages';
import PageHeader from '../PageHeader';
import UserInfo from '../UserInfo';
import HeaderSettings from '../PageHeader/HeaderSettings';
import identiqBlack from '../../../images/identiq-black.svg';
import identiqWhite from '../../../images/identiq-white.svg';

const SiteListView = ({ realm, sitesReady }) => {
  const dispatch = useDispatch();
  const { theme } = useContext(AppContext);

  const sites = useSelector(getSites);
  const sitesLoaded = useSelector(getSitesLoaded);
  const thumbnails = useSelector(getThumbnails);

  const [filter, setFilter] = useState('');
  const [siteToDelete, setSiteToDelete] = useState(null);

  const nightmode = theme === 'dark';

  useEffect(() => {
    dispatch(clearSiteData());
  }, []);

  useEffect(() => {
    if (sitesLoaded) {
      sites.forEach(({ model, version }) => {
        if (model && version) {
          dispatch(requestModelThumbnail(model, version));
        }
      });
      const loader = document.getElementById('first-loader');
      if (loader) loader.remove();
    }
  }, [sites, sitesLoaded]);

  const sitesWithThumbs = useMemo(() => {
    if (sites && sites.length > 0) {
      return sites.map((site) => {
        if (site.model && site.version) {
          // eslint-disable-next-line no-param-reassign
          site.thumbnail = thumbnails[`${site.model}/${site.version}`];
        }
        return site;
      });
    }
    return [];
  }, [sites, thumbnails]);

  const filteredSites = useMemo(() => {
    if (sitesWithThumbs && sitesWithThumbs.length > 0) {
      return sitesWithThumbs.filter(({ name, location: { city, country } = {} }) => {
        if (name && city && country) {
          const needles = [name, city, country].map((s) => s.toLowerCase());
          return needles.some((needle) => needle.includes(filter.toLowerCase()));
        }
        return false;
      });
    }
    return [];
  }, [sitesWithThumbs, filter]);

  const onDeleteSite = (name, id) => {
    setSiteToDelete({ name, id });
  };

  const itemMenuOptions = (siteName, siteId) => [
    {
      component: <div>Delete site</div>,
      onSelect: () => onDeleteSite(siteName, siteId),
    },
  ];

  const isTenantAdmin = useSelector((state) => getIsTenantOrSuperAdmin(state, realm));

  return (
    <>
      <Notifications portalOnly />
      <PageHeader
        title={{ type: 'image', value: nightmode ? identiqWhite : identiqBlack }}
        realm={realm}
        sitesList
        setFilter={setFilter}
      >
        <Notifications />
        <UserInfo />
        {isTenantAdmin && <HeaderSettings noSiteView />}
      </PageHeader>
      <div className="site-list-view-component">
        <div className={`sidebar ${nightmode ? 'dark-theme' : 'light-theme'}`}>
          <div className="panel"></div>
          <div className="site-list custom-scrollbar">
            {filteredSites.map(({ id, name, thumbnail, location: { city, country } }, i) => (
              <Link
                className="site site-list-link"
                key={id}
                to={`/sites/${id}/`}
              >
                <div className="thumbnail">
                  <SiteThumbnail
                    src={thumbnail}
                    name={name}
                  />
                </div>
                <div className="info">
                  <div className="name">{name}</div>
                  <div className="location">
                    {city}, {country}
                  </div>
                </div>
                {isTenantAdmin ? (
                  <>
                    <DropdownMenu
                      trigger="avt-menu-dots"
                      menuOptions={itemMenuOptions(name, id)}
                      className="site-item-menu"
                      menuXPlacement="left"
                      menuYPlacement={i === filteredSites.length - 1 ? 'top' : 'bottom'}
                    />
                  </>
                ) : (
                  <Icon
                    size="m"
                    icon="eye"
                  />
                )}
              </Link>
            ))}
          </div>
        </div>
        <div className="map-view">
          <SystemMessages />
          <Map
            sites={filteredSites}
            isReady={sitesReady}
            nightmode={nightmode}
          />
        </div>
        {siteToDelete && (
          <ConfirmationDialog
            onCancel={() => setSiteToDelete(null)}
            onConfirm={() => {
              dispatch(deleteSite(siteToDelete.id));
              setSiteToDelete(null);
            }}
            confirmType="danger"
            title="Delete Site"
            body={`Are you sure you want to delete ${siteToDelete.name}?`}
            confirmText="Delete"
          />
        )}
      </div>
    </>
  );
};

export default SiteListView;
