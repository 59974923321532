import React, { useMemo, useState } from 'react';
import { parseISO, compareDesc } from 'date-fns';

import { Button, Icon } from '@iq/react-components';
import { formatBytes } from '../../../../../utils';
import FiletypeIcon from '../../../../FiletypeIcon';
import Pill from '../../../../Pill';
import FileListBookmarks from '../FileListBookmarks';
import { DropdownMenu } from '../../../../DropdownMenu';

const FileList = ({
  files,
  onPreview,
  filterValue,
  tagFilter,
  activeComponentId,
  onSelectFile,
  disableSelect,
  getDropdownOptions = () => [],
}) => {
  const sorted = useMemo(
    () => files.sort(({ createdAt: a }, { createdAt: b }) => compareDesc(parseISO(a), parseISO(b))),
    [files]
  );
  const [visibleBookmarkLists, setVisibleBookmarkLists] = useState([]);

  const onToggleBookmarkList = (fileId) => {
    setVisibleBookmarkLists(() =>
      visibleBookmarkLists.indexOf(fileId) !== -1
        ? visibleBookmarkLists.filter((id) => id !== fileId)
        : [...visibleBookmarkLists, fileId]
    );
  };

  return (
    <div className="file-list-component">
      {sorted.map((file, i) => (
        <React.Fragment key={i}>
          <div className="file-component">
            {!disableSelect && (
              <div className="select-box">
                <Button
                  design="text"
                  tooltip="Select"
                >
                  <Icon
                    className={`checkbox ${file.isSelected ? 'checked' : ''}`}
                    icon={`${file.isSelected ? 'check-box' : 'check-box-outline-blank'}`}
                    size="s"
                    onClick={(e) => {
                      e.stopPropagation();
                      onSelectFile(file);
                    }}
                  />
                </Button>
              </div>
            )}
            <div className="filetype">{<FiletypeIcon file={file} />}</div>
            <div className="file-info">
              <div
                className="filename"
                onClick={() => onPreview(file)}
              >
                {file.filename}
              </div>
              <div className="file-extras-wrapper">
                {file.bookmarks.length > 0 && (
                  <div className="file-pill">
                    <Pill
                      icon="bookmark"
                      text={file.bookmarks.length}
                      onClick={() => onToggleBookmarkList(file.id)}
                    />
                  </div>
                )}
                <div className="filesize">{formatBytes(file.size)}</div>
              </div>
            </div>
            {!!getDropdownOptions(file).length && (
              <DropdownMenu
                trigger="abb-menu-narrow"
                menuOptions={getDropdownOptions(file)}
                menuXPlacement="left"
                styles={{
                  dropdownIndicator: { padding: 'calc(0.15rem + 2.5px)' },
                }}
              />
            )}
          </div>
          <FileListBookmarks
            onPreview={onPreview}
            file={file}
            filterValue={filterValue}
            bookmarks={file.bookmarks}
            tagFilter={tagFilter}
            activeComponentId={activeComponentId}
            bookmarkListToggled={visibleBookmarkLists.indexOf(file.id) !== -1}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

export default FileList;
