.no-grants-view-component {
  background: #f2f2f2;
  font-family: arial, sans-serif;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: url("/opt/src/images/login.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  color: #333;
  padding-top: 15vh;
  box-sizing: border-box;
  height: 100vh;
}
.no-grants-view-component .no-grants-view-panel {
  margin: 0 auto;
  max-width: 60rem;
  padding: 1rem 2rem 2rem;
  background: #fff;
  max-width: 25rem;
}
.no-grants-view-component .no-grants-view-panel button {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 2rem;
}
.no-grants-view-component .no-grants-view-panel p {
  margin: 0.5rem 0;
}
.no-grants-view-component .no-grants-view-panel img {
  width: 75%;
  padding: 0.5rem 0;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvTm9HcmFudHNWaWV3Iiwic291cmNlcyI6WyJOb0dyYW50c1ZpZXcuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTs7QUFHRjtFQUNFO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0ICdAaXEvcmVhY3QtY29tcG9uZW50cy9kaXN0L2NvbG9ycy5zY3NzJztcblxuLm5vLWdyYW50cy12aWV3LWNvbXBvbmVudCB7XG4gIGJhY2tncm91bmQ6ICNmMmYyZjI7XG4gIGZvbnQtZmFtaWx5OiBhcmlhbCwgc2Fucy1zZXJpZjtcbiAgbGluZS1oZWlnaHQ6IG5vcm1hbDtcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAganVzdGlmeS1jb250ZW50OiBmbGV4LXN0YXJ0O1xuICBiYWNrZ3JvdW5kOiB1cmwoJy9vcHQvc3JjL2ltYWdlcy9sb2dpbi5qcGcnKTtcbiAgYmFja2dyb3VuZC1zaXplOiBjb3ZlcjtcbiAgYmFja2dyb3VuZC1yZXBlYXQ6IG5vLXJlcGVhdDtcbiAgY29sb3I6ICMzMzM7XG4gIHBhZGRpbmctdG9wOiAxNXZoO1xuICBib3gtc2l6aW5nOiBib3JkZXItYm94O1xuICBoZWlnaHQ6IDEwMHZoO1xuXG4gIC5uby1ncmFudHMtdmlldy1wYW5lbCB7XG4gICAgbWFyZ2luOiAwIGF1dG87XG4gICAgbWF4LXdpZHRoOiA2MHJlbTtcbiAgICBwYWRkaW5nOiAxcmVtIDJyZW0gMnJlbTtcbiAgICBiYWNrZ3JvdW5kOiAjZmZmO1xuICAgIG1heC13aWR0aDogMjVyZW07XG5cbiAgICBidXR0b24ge1xuICAgICAgZGlzcGxheTogYmxvY2s7XG4gICAgICB3aWR0aDogMTAwJTtcbiAgICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgICAgIG1hcmdpbi10b3A6IDJyZW07XG4gICAgfVxuXG4gICAgcCB7XG4gICAgICBtYXJnaW46IDAuNXJlbSAwO1xuICAgIH1cblxuICAgIGltZyB7XG4gICAgICB3aWR0aDogNzUlO1xuICAgICAgcGFkZGluZzogMC41cmVtIDA7XG4gICAgfVxuICB9XG59XG4iXX0= */