.layout-item.styled-item {
  margin: auto;
  margin-bottom: 0.5rem;
}
.layout-item.styled-item > .styled-item__content > .styled-item__header .updated {
  opacity: 0.7;
  font-family: monospace;
  font-size: 0.9rem;
}
.layout-item.styled-item > .styled-item__content > .styled-item__header .avt-react-components--icon {
  transform: rotate(0);
  transition: transform 0.1s linear;
}
.layout-item.styled-item.open > .styled-item__content > .styled-item__header .avt-react-components--icon {
  transform: rotate(0.25turn);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvU2l0ZUFkbWluVmlldy9MYXlvdXRWaWV3L2NvbXBvbmVudHMvTGF5b3V0SXRlbSIsInNvdXJjZXMiOlsiTGF5b3V0SXRlbS5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0U7RUFDQTs7QUFHRTtFQUNFO0VBQ0E7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7O0FBTUE7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgJ0BpcS9yZWFjdC1jb21wb25lbnRzL2Rpc3QvY29sb3JzLnNjc3MnO1xuXG4ubGF5b3V0LWl0ZW0uc3R5bGVkLWl0ZW0ge1xuICBtYXJnaW46IGF1dG87XG4gIG1hcmdpbi1ib3R0b206IDAuNXJlbTtcblxuICA+IC5zdHlsZWQtaXRlbV9fY29udGVudCA+IC5zdHlsZWQtaXRlbV9faGVhZGVyIHtcbiAgICAudXBkYXRlZCB7XG4gICAgICBvcGFjaXR5OiAwLjc7XG4gICAgICBmb250LWZhbWlseTogbW9ub3NwYWNlO1xuICAgICAgZm9udC1zaXplOiAwLjlyZW07XG4gICAgfVxuXG4gICAgLmF2dC1yZWFjdC1jb21wb25lbnRzLS1pY29uIHtcbiAgICAgIHRyYW5zZm9ybTogcm90YXRlKDApO1xuICAgICAgdHJhbnNpdGlvbjogdHJhbnNmb3JtIDAuMXMgbGluZWFyO1xuICAgIH1cbiAgfVxuXG4gICYub3BlbiB7XG4gICAgPiAuc3R5bGVkLWl0ZW1fX2NvbnRlbnQgPiAuc3R5bGVkLWl0ZW1fX2hlYWRlciB7XG4gICAgICAuYXZ0LXJlYWN0LWNvbXBvbmVudHMtLWljb24ge1xuICAgICAgICB0cmFuc2Zvcm06IHJvdGF0ZSgwLjI1dHVybik7XG4gICAgICB9XG4gICAgfVxuICB9XG59XG4iXX0= */