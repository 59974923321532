.section-list-item.styled-item .properties-title,
.section-list-item.styled-item .columns-title {
  font-weight: 500;
  margin: 1rem 0;
}

.dark-theme .section-list-item.styled-item {
  background: rgba(255, 255, 255, 0.075);
}
.dark-theme .section-list-item.styled-item .json-editor-component .prop-table .row,
.dark-theme .section-list-item.styled-item .json-editor-component .key,
.dark-theme .section-list-item.styled-item .json-editor-component .value {
  border: 1px solid #333333 !important;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvU2l0ZUFkbWluVmlldy9MYXlvdXRWaWV3L2NvbXBvbmVudHMvU2VjdGlvbkl0ZW0iLCJzb3VyY2VzIjpbIlNlY3Rpb25JdGVtLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBR0U7QUFBQTtFQUVFO0VBQ0E7OztBQUtGO0VBQ0U7O0FBR0U7QUFBQTtBQUFBO0VBR0UiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0ICdAaXEvcmVhY3QtY29tcG9uZW50cy9kaXN0L2NvbG9ycy5zY3NzJztcblxuLnNlY3Rpb24tbGlzdC1pdGVtLnN0eWxlZC1pdGVtIHtcbiAgLnByb3BlcnRpZXMtdGl0bGUsXG4gIC5jb2x1bW5zLXRpdGxlIHtcbiAgICBmb250LXdlaWdodDogNTAwO1xuICAgIG1hcmdpbjogMXJlbSAwO1xuICB9XG59XG5cbi5kYXJrLXRoZW1lIHtcbiAgLnNlY3Rpb24tbGlzdC1pdGVtLnN0eWxlZC1pdGVtIHtcbiAgICBiYWNrZ3JvdW5kOiByZ2JhKCNmZmYsIDAuMDc1KTtcblxuICAgIC5qc29uLWVkaXRvci1jb21wb25lbnQge1xuICAgICAgLnByb3AtdGFibGUgLnJvdyxcbiAgICAgIC5rZXksXG4gICAgICAudmFsdWUge1xuICAgICAgICBib3JkZXI6IDFweCBzb2xpZCAkR3JleTgwICFpbXBvcnRhbnQ7XG4gICAgICB9XG4gICAgfVxuICB9XG59XG4iXX0= */