import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Brush } from '@visx/brush';
import { Bar } from '@visx/shape';
import { scaleLinear, scaleTime } from '@visx/scale';
import { AxisBottom } from '@visx/axis';
import { Icon, Button } from '@iq/react-components';

import { useClientSize } from '../../../utils';
import { getDateTickFormatter } from '../../../datetimeUtils';
import {
  useDateDomainHistogram,
  useOnSelectedDateDomainChange,
  useSelectedDateDomain,
  usePanelDateDomain,
  useStyling,
} from './EventTimelineState';
import { useIsLoading } from '../../LoadingLayer';
import { getTimezone } from '../../../bundles/application';

const margin = {
  left: 70,
  top: 0,
  bottom: 30,
  right: 0,
};

const EventBrush = ({ type }) => {
  const { tickStroke, background, indicatorStroke, xTickLabelProps, brushSelectionIndicator } =
    useStyling();
  const timezone = useSelector(getTimezone);
  const [{ width }, clientRef] = useClientSize();
  const height = 75;
  const onSetSelectedDomain = useOnSelectedDateDomainChange();
  const selectedDateDomain = useSelectedDateDomain();
  const panelDateDomain = usePanelDateDomain();
  const { response: { min, max, buckets = [], dateDomain = panelDateDomain } = {} } =
    useDateDomainHistogram(type);
  const loading = useIsLoading();
  const brushRef = useRef();
  const periodInMs = dateDomain.length ? dateDomain[1] - dateDomain[0] : 0;

  const brushWidth = Math.max(0, width - margin.left - margin.right);
  const brushHeight = Math.max(0, height - margin.bottom);
  const xScale = useMemo(
    () =>
      dateDomain.length
        ? scaleTime({
            range: [0, brushWidth],
            clamp: true,
            domain: dateDomain,
          })
        : null,
    [brushWidth, dateDomain, loading]
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [0, 0],
        range: [margin.bottom, brushHeight],
        nice: true,
      }),
    [brushHeight]
  );

  const onResetBrush = () => {
    if (brushRef && brushRef.current) {
      brushRef.current.reset();
    }
  };

  const handleChange = useCallback(
    (res) => {
      const next = res ? [new Date(res.x0).getTime(), new Date(res.x1).getTime(), null] : null;
      onSetSelectedDomain(next || dateDomain);
    },
    [onSetSelectedDomain, dateDomain, xScale]
  );

  const initialBrushPosition = useMemo(
    () =>
      selectedDateDomain &&
      xScale && {
        start: { x: xScale(selectedDateDomain[0]) },
        end: { x: xScale(selectedDateDomain[1]) },
      },
    [xScale, selectedDateDomain]
  );

  useEffect(() => {
    if (!selectedDateDomain) {
      onResetBrush();
    }
  }, [selectedDateDomain, xScale]);

  useEffect(() => {
    if (initialBrushPosition && xScale && brushRef && brushRef.current) {
      const updater = (prevBrush) => {
        const newExtent = brushRef.current.getExtent(
          initialBrushPosition.start,
          initialBrushPosition.end
        );

        return {
          ...prevBrush,
          start: { y: newExtent.y0, x: newExtent.x0 },
          end: { y: newExtent.y1, x: newExtent.x1 },
          extent: newExtent,
        };
      };
      brushRef.current.updateBrush(updater);
    }
  }, [initialBrushPosition, xScale]);

  const heightScale = scaleLinear({
    domain: [min, max],
    range: [2, brushHeight],
  });

  const presentIndicator = useMemo(() => {
    const now = Date.now();

    if (!dateDomain[1] || dateDomain[1] - now <= 0) {
      return null;
    }

    const x = xScale(now);
    return (
      <line
        x1={x}
        y1={0}
        x2={x}
        y2={height - margin.bottom}
        stroke={indicatorStroke}
        strokeOpacity={0.5}
      />
    );
  }, [xScale, height, dateDomain]);

  if (periodInMs) {
    return (
      <div
        style={{ display: 'flex' }}
        ref={clientRef}
      >
        <div
          style={{
            minWidth: `${margin.left}px`,
            paddingBottom: `${margin.bottom}px`,
            paddingRight: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            activity="secondary"
            type="button"
            tooltip="Reset zoom"
            tooltipPosition="top"
            onClick={() => onSetSelectedDomain(dateDomain)}
          >
            <Icon
              icon="abb-zoom-out"
              size="s"
            />
          </Button>
        </div>
        <svg
          width={brushWidth}
          height={height}
        >
          {presentIndicator}
          <rect
            fillOpacity={0}
            stroke={tickStroke}
            width={Math.max(0, brushWidth)}
            height={brushHeight}
          />
          <AxisBottom
            scale={xScale}
            top={brushHeight}
            width={Math.max(0, brushWidth)}
            stroke={tickStroke}
            tickStroke={tickStroke}
            tickLabelProps={xTickLabelProps}
            numTicks={Math.max(Math.floor(brushWidth / 80), 1)}
            tickFormat={getDateTickFormatter(periodInMs, timezone, {
              scale: xScale,
              width: brushWidth,
            })}
            hideAxisLine
          />
          <rect
            // covers removed end tick line
            x={brushWidth - 20}
            y={brushHeight + 1}
            width={30}
            height={8}
            fill={background}
          />
          {buckets.map((bucket) => (
            <Bar
              key={bucket.from.toJSON()}
              x={xScale(bucket.from)}
              y={heightScale.range()[1] - heightScale(bucket.count)}
              height={heightScale(bucket.count)}
              width={Math.max(0, xScale(bucket.to) - xScale(bucket.from))}
              fill="rgba(66, 66, 66, .5)"
            />
          ))}
          <Brush
            xScale={xScale}
            yScale={yScale}
            width={Math.max(0, brushWidth)}
            height={brushHeight}
            handleSize={8}
            innerRef={brushRef}
            initialBrushPosition={initialBrushPosition}
            onBrushEnd={handleChange}
            selectedBoxStyle={{
              ...brushSelectionIndicator,
              y: brushSelectionIndicator.strokeWidth - 1,
            }}
          />
        </svg>
      </div>
    );
  }
  return null;
};

export default EventBrush;
