/* eslint-disable no-nested-ternary */
import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Select } from '@iq/react-components';
import './SelectWidget.scss';
import { NEW_PANEL } from '../../../constants';

const SelectWidget = (props) => {
  const {
    disabled,
    readonly,
    required,
    formContext,
    value,
    schema: {
      default: defaultValue,
      isMulti,
      isClearable,
      noOptionsMessage,
      placeholder: schemaPlaceholder,
      editable = true,
      groupedOptions,
    },
    options: { enumOptions = [] },
    onChange,
    ...otherProps
  } = props;

  const getLabel = (val) => enumOptions.find((o) => o.value === val)?.label;
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (formContext.type === NEW_PANEL && !isChanged && defaultValue && value.length === 0) {
      onChange(defaultValue);
    }
  }, [defaultValue]);

  const theValue = useMemo(() => {
    if (isMulti) {
      return (value || []).map((v) => ({
        label: enumOptions.find((o) => o.value === v)?.label,
        value: v,
      }));
    }
    if (value || value === 0) {
      return { label: getLabel(value), value };
    }
    if (defaultValue) {
      return { label: getLabel(defaultValue), value: defaultValue };
    }
    return undefined;
  }, [value, defaultValue, enumOptions]);

  const setValues = useCallback(
    (selected) => {
      if (isMulti) {
        setIsChanged(true);
        onChange((selected || []).map((s) => s.value));
      } else {
        onChange(selected && (selected.value || selected.value === 0) ? selected.value : null);
      }
    },
    [onChange, isMulti]
  );

  const getEmptyMsg = (text) => <div>{text}</div>;

  const overrideStyles = {
    container: (base) => ({ ...base, lineHeight: 'normal' }),
    singleValue: (base) => ({ ...base, lineHeight: 'normal' }),
    control: (base) => ({ ...base, lineHeight: 'normal' }),
  };

  const options =
    groupedOptions ||
    enumOptions.map((opt) => {
      const cleanedOpt = { ...opt };
      delete cleanedOpt.schema;
      return cleanedOpt;
    });

  return editable ? (
    <Select
      {...otherProps}
      key={`unique_select_key`}
      className="schema-select-widget"
      isMulti={isMulti}
      isClearable={isClearable}
      closeMenuOnSelect={!isMulti}
      isDisabled={disabled}
      readOnly={readonly}
      required={required}
      placeholder={schemaPlaceholder}
      menuPlacement="auto"
      value={theValue}
      onChange={setValues}
      options={options}
      noOptionsMessage={() => getEmptyMsg(noOptionsMessage || 'No options')}
      styles={overrideStyles}
    />
  ) : (
    <>
      {theValue instanceof Array ? (
        theValue.map((val, i) => {
          const key = `${val}-${i}`;
          return (
            <div
              key={key}
              className="view-widget-multi"
            >
              <label className="view-widget">{`${val.label}`}</label>
            </div>
          );
        })
      ) : (
        <label className="view-widget">{`${theValue?.label}`}</label>
      )}
    </>
  );
};

export default SelectWidget;
