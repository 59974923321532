import React, { useState, useEffect, useRef } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'; // peer-dep of react-dates
import { SingleDatePicker } from 'react-dates';
import { ICON_AFTER_POSITION } from 'react-dates/constants';
import { Icon } from '@iq/react-components';

const CALENDAR_ID = 'calendar-toggle';

// Date Picker typing requires React.Node (class)
class CalendarToggleIcon extends React.Component {
  render() {
    return (
      <Icon
        icon="abb-calendar"
        size="s"
        onClick={this.props.onClick}
        id={this.props.id}
      />
    );
  }
}

const DatePicker = ({
  id,
  value: date,
  onChange,
  onCalendarOpen,
  onTextComplete,
  onTabOrArrowBack,
}) => {
  const [focused, _setFocused] = useState(false);
  const [focusedMonth, setFocusedMonth] = useState(null);
  const [localDate, _setLocalDate] = useState(date);
  const [tempYear, _setTempYear] = useState(null);
  const [tempMonth, _setTempMonth] = useState(null);
  const [tempDay, _setTempDay] = useState(null);

  const inputRef = useRef(null);
  const calendarRef = useRef(null);
  const focusedRef = useRef(focused);
  const localDateRef = useRef(localDate);
  const tempYearRef = useRef(tempYear);
  const tempMonthRef = useRef(tempMonth);
  const tempDayRef = useRef(tempDay);

  const setFocused = (isFocused) => {
    focusedRef.current = isFocused;
    _setFocused(isFocused);
  };

  const setLocalDate = (d) => {
    localDateRef.current = d;
    _setLocalDate(d);
  };

  const setTempYear = (year) => {
    tempYearRef.current = year;
    _setTempYear(year);
  };

  const setTempMonth = (month) => {
    tempMonthRef.current = month;
    _setTempMonth(month);
  };

  const setTempDay = (day) => {
    tempDayRef.current = day;
    _setTempDay(day);
  };

  function refreshDate(d) {
    if (d) {
      setFocusedMonth(d);
      setLocalDate(d);
    } else {
      setLocalDate(d);
    }
  }

  const handleOnChange = (value) => {
    /*
     * selected value comes in at 00:00:00 so we have to re-add the initial time
     * so that timezone conversions work correctly
     */
    const update = new Date(localDateRef.current);

    if (value && moment(value).isValid()) {
      const newDate = new Date(value);
      update.setFullYear(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
    }

    refreshDate(update.getTime());
    return onChange(update.getTime());
  };

  useEffect(() => refreshDate(date), [date]);

  useEffect(() => {
    if (inputRef.current) {
      const calendarBtns = document.querySelectorAll('.SingleDatePickerInput_calendarIcon');
      calendarBtns.forEach((btn) => {
        // eslint-disable-next-line no-param-reassign
        btn.tabIndex = '-1';
      });
    }
  }, [inputRef.current]);

  const getNextSelectionRange = (selectionStart, shift) => {
    if (shift) {
      if (selectionStart === 5) {
        return [0, 4];
      }
      if (selectionStart === 8) {
        return [5, 7];
      }
      return [8, 10];
    }
    if (selectionStart === 0) {
      return [5, 7];
    }
    if (selectionStart === 5) {
      return [8, 10];
    }
    return [0, 4];
  };

  function pad(number, length) {
    return `${'0'.repeat(length - number.toString().length)}${number}`;
  }

  function getDateStr(y, m, d) {
    return `${pad(y, 4)}-${pad(m, 2)}-${pad(d, 2)}`;
  }

  function clearTemps() {
    setTempYear(null);
    setTempMonth(null);
    setTempDay(null);
  }

  const onKeyDown = (e) => {
    if (e.target && e.target.id === id) {
      const { selectionStart, selectionEnd, value: inputValue } = e.target;
      const [y, m, d] = inputValue.split('-');
      if (!focusedRef.current) {
        if (e.key === 'Tab' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
          // tab/move can occur before entering full period (e.g. '0021', tempYear = '21'),
          // or after adjusting full period with arrow up/down,
          // or after clearing period with delete
          if (
            (e.key === 'Tab' && !(e.shiftKey && selectionStart === 0 && !onTabOrArrowBack)) ||
            e.key === 'ArrowLeft' ||
            e.key === 'ArrowRight'
          ) {
            e.preventDefault();
          }
          let value;
          const shift = (e.key === 'Tab' && e.shiftKey) || e.key === 'ArrowLeft';
          const selectionRange = getNextSelectionRange(selectionStart, shift);

          if (selectionStart === 0) {
            if (
              Number.isNaN(parseInt(y, 10)) ||
              (tempYearRef.current && tempYearRef.current.value.length < 4)
            ) {
              // if tab after delete (y = 'yyyy') or before completing input
              value = moment(localDateRef.current).year().toString();
              e.target.value = getDateStr(value, m, d);
              if (!shift) {
                e.target.setSelectionRange(...selectionRange);
              }
              setTempYear(null);
            } else if (!shift && (!tempYearRef.current || !tempYearRef.current.value)) {
              e.target.setSelectionRange(...selectionRange);
            } else if (shift && onTabOrArrowBack) {
              onTabOrArrowBack();
            }
          } else if (selectionStart === 5) {
            if (
              Number.isNaN(parseInt(m, 10)) ||
              (tempMonthRef.current && tempMonthRef.current.value.length < 2)
            ) {
              // if tab after delete (m = 'mm') or before completing input
              value = moment(localDateRef.current).month() + 1;
              e.target.value = getDateStr(y, value, d);
              e.target.setSelectionRange(...selectionRange);
              setTempMonth(null);
            } else if (!tempMonthRef.current || !tempMonthRef.current.value) {
              e.target.setSelectionRange(...selectionRange);
            }
          } else if (selectionStart === 8) {
            if (
              Number.isNaN(parseInt(d, 10)) ||
              (tempDayRef.current && tempDayRef.current.value.length < 2)
            ) {
              // if tab after delete (d = 'dd') or before completing input
              value = pad(moment(localDateRef.current).date(), 2);
              e.target.value = getDateStr(y, m, value);
              if (shift) {
                e.target.setSelectionRange(...selectionRange);
              }
              setTempDay(null);
            } else if (shift && (!tempDayRef.current || !tempDayRef.current.value)) {
              e.target.setSelectionRange(...selectionRange);
            } else if ((e.key === 'ArrowRight' || e.key === 'Tab') && onTextComplete) {
              onTextComplete();
            }
          } else {
            e.target.setSelectionRange(...selectionRange);
          }
        }

        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
          e.preventDefault();
          e.stopPropagation();
          const [yI, mI, dI] = [y, m, d].map((v) => parseInt(v, 10));
          let value;
          if (selectionStart === 0) {
            if (e.key === 'ArrowUp') {
              value = pad(yI < 9998 ? yI + 1 : '0000', 4);
            } else {
              value = pad(yI > 1 ? yI - 1 : '9999', 4);
            }
            setTempYear({ value, retainFocus: true });
          } else if (selectionStart === 5) {
            if (e.key === 'ArrowUp') {
              value = pad(mI < 12 ? mI + 1 : '01', 2);
            } else {
              value = pad(mI > 1 ? mI - 1 : '12', 2);
            }
            setTempMonth({ value, retainFocus: true });
          } else if (selectionStart === 8) {
            const days = moment(`${y}-${m}`, 'YYYY-MM').daysInMonth();
            if (e.key === 'ArrowUp') {
              value = pad(dI < (days || 27) ? dI + 1 : '01', 2);
            } else {
              value = pad(dI > 1 ? dI - 1 : `${days || '28'}`, 2);
            }
            setTempDay({ value, retainFocus: true });
          }
        }

        if (inputValue && e.key === 'Backspace') {
          e.preventDefault();
          if (selectionStart === 0 && selectionEnd === 4) {
            e.target.value = `yyyy-${inputValue.slice(5)}`;
            e.target.setSelectionRange(0, 4);
            setTempYear(null);
          } else if (selectionStart === 5 && selectionEnd === 7) {
            e.target.value = `${inputValue.slice(0, 5)}mm${inputValue.slice(7)}`;
            e.target.setSelectionRange(5, 7);
            setTempMonth(null);
          } else if (selectionStart === 8 && selectionEnd === 10) {
            e.target.value = `${inputValue.slice(0, 8)}dd`;
            e.target.setSelectionRange(8, 10);
            setTempDay(null);
          }
        }
      }

      if (focusedRef.current && e.key === 'Tab' && e.shiftKey && selectionStart === 0) {
        setFocused(false);
        clearTemps();
      }
    } else if (e.key === 'Tab' && document.activeElement.classList.contains('CalendarDay')) {
      setFocused(false);
      clearTemps();
    }
  };

  const onKeyPress = (e) => {
    if (!focusedRef.current && e.target && e.target.id === id) {
      const { selectionStart, value: inputValue } = e.target;
      e.preventDefault();
      if (inputValue && parseInt(e.key, 10) >= 0 && e.key >= 0 && e.key <= 9) {
        const [y, m, d] = inputValue.split('-');
        const days = moment(`${y}-${m}`, 'YYYY-MM').daysInMonth();
        let value = e.key;

        if (selectionStart === 0) {
          if (
            !tempYearRef.current ||
            (tempYearRef.current.value && tempYearRef.current.value.length < 4)
          ) {
            value = `${(tempYearRef.current && tempYearRef.current.value) || ''}${e.key}`;
            e.target.value = getDateStr(value, m, d);
          }
          setTempYear({ value });
        }

        if (selectionStart === 5) {
          if (tempMonthRef.current && tempMonthRef.current.value === '0' && e.key === '0') {
            e.target.value = getDateStr(y, e.key, d);
            e.target.setSelectionRange(5, 7);
            setTempMonth({ value: '0' });
          } else {
            if (!tempMonthRef.current && e.key < 2) {
              e.target.value = getDateStr(y, e.key, d);
              e.target.setSelectionRange(5, 7);
            } else if (
              (!tempMonthRef.current && e.key >= 2) ||
              (tempMonthRef.current && tempMonthRef.current.value === '0' && e.key > 0)
            ) {
              value = pad(e.key, 2);
            } else if (tempMonthRef.current && tempMonthRef.current.value === '1' && e.key < 3) {
              value = `1${e.key}`;
            } else {
              value = '12';
            }
            setTempMonth({ value });
          }
        }

        if (selectionStart === 8) {
          if (tempDayRef.current && tempDayRef.current.value === '0' && e.key === '0') {
            e.target.value = getDateStr(y, m, e.key);
            e.target.setSelectionRange(8, 10);
            setTempDay({ value: '0' });
          } else {
            if (
              (!tempDayRef.current && e.key === '0') ||
              (!tempDayRef.current && (e.key < 3 || (e.key === '3' && m !== '02')))
            ) {
              e.target.value = getDateStr(y, m, e.key);
              e.target.setSelectionRange(8, 10);
            } else if (
              (!tempDayRef.current && (e.key > 3 || (e.key === '3' && m === '02'))) ||
              (tempDayRef.current.value === '0' && e.key > 0)
            ) {
              value = pad(e.key, 2);
            } else if (
              tempDayRef.current.value === '1' ||
              (tempDayRef.current.value === '2' && `2${e.key}` < days + 1) ||
              (tempDayRef.current.value === '3' && `3${e.key}` < days + 1)
            ) {
              value = `${tempDayRef.current.value}${e.key}`;
            } else {
              value = days.toString();
            }
            setTempDay({ value });
          }
        }
      }
    } else if (focusedRef.current && e.target && e.target.id === id) {
      e.preventDefault();
    }
  };

  // only use localDate in deps array since we need update to occur
  // before setting selection and clearing temp values
  useEffect(() => {
    const dateInput = document.getElementById(id);
    if (dateInput && localDate) {
      if (tempYear) {
        if (tempYear.value.length === 4) {
          if (tempYear.retainFocus) {
            dateInput.setSelectionRange(0, 4);
          } else if (!tempYear.shift) {
            dateInput.setSelectionRange(5, 7);
          }
          setTempYear(null);
        } else {
          dateInput.setSelectionRange(0, 4);
        }
      }

      if (tempMonth) {
        if (tempMonth.value.length === 2) {
          if (tempMonth.retainFocus) {
            dateInput.setSelectionRange(5, 7);
          } else if (tempMonth.shift) {
            dateInput.setSelectionRange(0, 4);
          } else {
            dateInput.setSelectionRange(8, 10);
          }
          setTempMonth(null);
        } else {
          dateInput.setSelectionRange(5, 7);
        }
      }

      if (tempDay) {
        if (tempDay.value.length === 2) {
          if (tempDay.retainFocus) {
            dateInput.setSelectionRange(8, 10);
          } else if (tempDay.shift) {
            dateInput.setSelectionRange(5, 7);
          } else if (onTextComplete) {
            onTextComplete();
          } else {
            dateInput.setSelectionRange(8, 10);
          }
          setTempDay(null);
        } else {
          dateInput.setSelectionRange(8, 10);
        }
      }
    }
  }, [localDate]);

  useEffect(() => {
    const dateInput = document.getElementById(id);
    if (dateInput && tempYear && localDate) {
      // eslint-disable-next-line no-unused-vars
      const [y, m, d] = dateInput.value.split('-');
      const ldm = moment(localDate);
      if (tempYear.value.length === 4 && tempYear.value !== ldm.year()) {
        handleOnChange(moment(getDateStr(tempYear.value, m, d)));
      } else if (tempYear.value.length === 4 && tempYear.value === ldm.year()) {
        dateInput.value = getDateStr(ldm.year(), ldm.month() + 1, ldm.date());
        dateInput.setSelectionRange(5, 7);
        setTempYear(null);
      } else {
        dateInput.setSelectionRange(0, 4);
      }
    }
  }, [tempYear, localDate]);

  useEffect(() => {
    const dateInput = document.getElementById(id);

    if (dateInput && tempMonth && localDate) {
      // eslint-disable-next-line no-unused-vars
      const [y, m, d] = dateInput.value.split('-');
      const ldm = moment(localDate);
      if (tempMonth.value.length === 2 && tempMonth.value !== pad(ldm.month() + 1, 2)) {
        handleOnChange(moment(getDateStr(y, tempMonth.value, d)));
      } else if (
        tempMonth.value.length === 2 &&
        tempMonth.value === pad(ldm.month() + 1, 2) &&
        !tempMonth.retainFocus
      ) {
        dateInput.value = getDateStr(ldm.year(), ldm.month() + 1, ldm.date());
        dateInput.setSelectionRange(8, 10);
        setTempMonth(null);
      } else {
        dateInput.setSelectionRange(5, 7);
      }
    }
  }, [tempMonth, localDate]);

  useEffect(() => {
    const dateInput = document.getElementById(id);

    if (dateInput && tempDay && localDate) {
      // eslint-disable-next-line no-unused-vars
      const [y, m, d] = dateInput.value.split('-');
      const ldm = moment(localDate);
      if (tempDay.value.length === 2 && tempDay.value !== pad(ldm.date(), 2)) {
        handleOnChange(moment(getDateStr(y, m, tempDay.value)));
      } else if (tempDay.value.length === 2 && tempDay.value === pad(ldm.date(), 2)) {
        dateInput.value = getDateStr(ldm.year(), ldm.month() + 1, ldm.date());
        setTempDay(null);
      } else {
        dateInput.setSelectionRange(8, 10);
      }
    }
  }, [tempDay, localDate]);

  const onClick = (e) => {
    if (!focusedRef.current && e.target && e.target.id === id) {
      const { selectionStart } = e.target;
      e.preventDefault();
      e.target.setSelectionRange(0, 0);
      if (selectionStart >= 0 && selectionStart <= 4) {
        e.target.setSelectionRange(0, 4);
      } else if (selectionStart >= 5 && selectionStart <= 7) {
        e.target.setSelectionRange(5, 7);
      } else if (selectionStart >= 8) {
        e.target.setSelectionRange(8, 10);
      }
    }

    const calendarIcon = document.getElementById(`${id}-${CALENDAR_ID}`);
    if (calendarIcon && e.target === calendarIcon) {
      const dateInput = document.getElementById(id);
      if (!focusedRef.current) {
        dateInput.setSelectionRange(0, 0);
      } else {
        e.preventDefault();
        e.stopPropagation();
        dateInput.blur();
      }
    }

    if (focusedRef.current) {
      const picker = document.querySelectorAll(`#date-picker-${id} .DayPicker_focusRegion`);
      if (picker[0] && !picker[0].contains(e.target)) {
        setFocused(false);
      }
    }
  };

  const onFocus = (e) => {
    if (e.target && e.target.id === id) {
      const { selectionStart, selectionEnd } = e.target;
      if (!focusedRef.current) {
        if (selectionStart === 0 && selectionEnd === 10) {
          e.preventDefault();
          e.target.setSelectionRange(0, 4);
        }
      } else if (selectionStart === 0 && selectionEnd === 10) {
        e.preventDefault();
        e.target.setSelectionRange(0, 0);
      }
    }
  };

  const onBlur = (e) => {
    if (!focusedRef.current && e.target && e.target.id === id) {
      const { value } = e.target;
      const year = value.split('-')[0];
      const month = value.split('-')[1];
      const day = value.split('-')[2];
      const updated = new Date(localDateRef.current);
      if (updated.setYear) {
        updated.setYear(year);
        updated.setMonth(month - 1);
        updated.setDate(day);
      }
      handleOnChange(updated);
      clearTemps();
    }
  };

  const onKeyUp = (e) => {
    if (focusedRef.current && e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      setFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, true);
    document.addEventListener('keypress', onKeyPress, true);
    document.addEventListener('click', onClick, true);
    document.addEventListener('touchend', onClick, true);
    document.addEventListener('focus', onFocus, true);
    document.addEventListener('blur', onBlur, true);
    document.addEventListener('keyup', onKeyUp, true);

    if (onCalendarOpen) {
      onCalendarOpen(focused);
    }

    return () => {
      document.removeEventListener('keydown', onKeyDown, true);
      document.removeEventListener('keypress', onKeyPress, true);
      document.removeEventListener('click', onClick, true);
      document.removeEventListener('touchend', onClick, true);
      document.removeEventListener('focus', onFocus, true);
      document.removeEventListener('blur', onBlur, true);
      document.removeEventListener('keyup', onKeyUp, true);
    };
  }, [focused]);

  const handleChange = (selectedDate) => {
    handleOnChange(selectedDate);
    setFocused(false);
  };

  return (
    <div
      className="date-picker"
      id={`date-picker-${id}`}
      ref={inputRef}
    >
      <SingleDatePicker
        date={(localDate && moment(localDate)) || moment()}
        onDateChange={handleChange}
        focused={focused}
        onFocusChange={() => null}
        placeholder="yyyy-mm-dd"
        required
        showDefaultInputIcon={false}
        customInputIcon={
          <CalendarToggleIcon
            onClick={() => setFocused((isFocused) => !isFocused)}
            id={`${id}-${CALENDAR_ID}`}
            ref={calendarRef}
          />
        }
        inputIconPosition={ICON_AFTER_POSITION}
        keepOpenOnDateSelect={false}
        noBorder
        small
        initialVisibleMonth={() => (focusedMonth && moment(focusedMonth)) || moment()}
        hideKeyboardShortcutsPanel
        displayFormat="YYYY-MM-DD"
        numberOfMonths={1}
        isOutsideRange={() => false}
        id={id}
      />
    </div>
  );
};

export default DatePicker;
