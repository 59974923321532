import React from 'react';
import { Input, Icon, Button } from '@iq/react-components';
import SelectPollingDateRange from '../../../../SelectPollingDateRange';

const Filters = ({ filterValue, setFilterValue, panelId }) => (
  <div className="filter-tools">
    <Input
      className="visualizations-panel-search"
      type="text"
      placeholder="Search..."
      value={filterValue}
      onChange={(e) => setFilterValue(e.target.value)}
    />

    <SelectPollingDateRange panelId={panelId} />
  </div>
);

const VisualizationsHeader = ({
  filterValue,
  format,
  hideToolbar,
  isFullScreen,
  setFilterValue,
  panelId,
  anySelected,
  allSelected,
  toggleAllSelected,
  openSignalViewer,
  exportTimeSeries,
}) => {
  return (
    <div className={`visualizations-panel-header ${isFullScreen ? 'widest' : format}`}>
      <div className="visualizations-panel-toolbar primary">
        <div className="signal-viewer-tools">
          <div
            className="signal-viewer-tools-select-all-label"
            onClick={toggleAllSelected}
          >
            <Icon
              className={`select-all ${allSelected ? 'checked' : ''}`}
              icon={`${allSelected ? 'check-box' : 'check-box-outline-blank'}`}
              size="s"
            />
            <span className="title">Select all</span>
          </div>
          <Button
            onClick={openSignalViewer}
            activity="secondary"
            icon={<Icon icon="abb-04-variables" />}
            tooltip="Signal Viewer"
            slim={true}
          />
          {anySelected && (
            <Button
              onClick={exportTimeSeries}
              activity="secondary"
              icon={<Icon icon="abb-download" />}
              tooltip="Download"
              slim={true}
            />
          )}
        </div>
        {!hideToolbar && (
          <Filters
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            panelId={panelId}
          />
        )}
      </div>
    </div>
  );
};

export default VisualizationsHeader;
