import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '@iq/react-components';

import AppContext from '../../AppContext';
import UploadStatus from '../UploadStatus';
import FileUploadStatus from '../FileUploadStatus';

const Sidebar = ({ site: { id }, pages = [], headerHeight }) => {
  const { theme, isTouchDevice } = useContext(AppContext);
  const [canSidebarExpand, setCanSidebarExpand] = useState(true);
  const expandSideBar = (expand) => (isTouchDevice ? setCanSidebarExpand(expand) : null);

  return (
    <>
      <section
        onMouseOver={() => expandSideBar(true)}
        style={{ top: `${headerHeight}px` }}
        className={`sidebar-component theme-${theme} ${canSidebarExpand ? '' : 'collapse'}`}
      >
        <nav className="main-nav">
          <ul className="pages">
            {pages.map(({ path, title, icon = 'abb-reports' }, i) => (
              <li key={`${path}-${i}`}>
                <NavLink
                  title={title}
                  exact
                  to={{
                    pathname: `/sites/${id}/${path}/`,
                    search: `page=${i}`,
                  }}
                  onClick={() => expandSideBar(false)}
                >
                  <Icon icon={icon} />
                  <span>{title}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </section>
      <UploadStatus />
      <FileUploadStatus />
    </>
  );
};

export default Sidebar;
