#global-modal-container .simple-modal-overlay .simple-modal-wrapper.event-file-modal-wrapper.size-m {
  max-width: 70rem;
}

.event-file-modal-wrapper__field-group, .event-file-modal-wrapper__errors > div {
  margin-bottom: 1rem;
}
.event-file-modal-wrapper__multi-field-group {
  display: flex;
  justify-content: space-between;
}
.event-file-modal-wrapper__multi-field-group > * {
  flex: 0 0 48%;
}
.event-file-modal-wrapper__actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}
.event-file-modal-wrapper__actions button:first-child {
  margin-right: 1rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvRXZlbnRGaWxlTW9kYWwiLCJzb3VyY2VzIjpbIkV2ZW50RmlsZU1vZGFsLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRTs7O0FBSUE7RUFFRTs7QUFHRjtFQUNFO0VBQ0E7O0FBRUE7RUFDRTs7QUFJSjtFQUNFO0VBQ0E7RUFDQTs7QUFFQTtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCAnQGlxL3JlYWN0LWNvbXBvbmVudHMvZGlzdC9jb2xvcnMuc2Nzcyc7XG5cbiNnbG9iYWwtbW9kYWwtY29udGFpbmVyIC5zaW1wbGUtbW9kYWwtb3ZlcmxheSAuc2ltcGxlLW1vZGFsLXdyYXBwZXIuZXZlbnQtZmlsZS1tb2RhbC13cmFwcGVyLnNpemUtbSB7XG4gIG1heC13aWR0aDogNzByZW07XG59XG5cbi5ldmVudC1maWxlLW1vZGFsLXdyYXBwZXIge1xuICAmX19maWVsZC1ncm91cCxcbiAgJl9fZXJyb3JzID4gZGl2IHtcbiAgICBtYXJnaW4tYm90dG9tOiAxcmVtO1xuICB9XG5cbiAgJl9fbXVsdGktZmllbGQtZ3JvdXAge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAganVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuO1xuXG4gICAgPiAqIHtcbiAgICAgIGZsZXg6IDAgMCA0OCU7XG4gICAgfVxuICB9XG5cbiAgJl9fYWN0aW9ucyB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IGZsZXgtZW5kO1xuICAgIG1hcmdpbi10b3A6IDJyZW07XG5cbiAgICBidXR0b246Zmlyc3QtY2hpbGQge1xuICAgICAgbWFyZ2luLXJpZ2h0OiAxcmVtO1xuICAgIH1cbiAgfVxufVxuXG4iXX0= */