/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Input, Spinner, Icon } from '@iq/react-components';

import { getActiveSite } from '../../../bundles/sites';
import {
  getVisualizations,
  getVisualizationsLoaded,
  resetVisualizations,
  upsertVisualization,
  requestVisualizations,
  requestDeleteVisualization,
  saveVisualization,
  bulkUpsertVisualization,
} from '../../../bundles/visualizations';
import { requestComponents, getComponents } from '../../../bundles/components';
import { setActiveComponentId } from '../../../bundles/application';
import { requestSources } from '../../../bundles/sources';

import Heading from '../../Heading';
import VisualizationEditor from '../../VisualizationEditor';
import { useEventListener } from '../../../utils';
import ListItem from '../../ListItem';
import { visualizations as vizMappings } from '../../Visualization';
import { DropdownMenu, DropdownTrigger } from '../../DropdownMenu';
import VisualizationSettingsModal from './components/VisualizationSettingsModal';

const VisualizationView = ({ wizardType }) => {
  const dispatch = useDispatch();

  const site = useSelector(getActiveSite);
  const visualizations = useSelector(getVisualizations);
  const visualizationNames = visualizations.map((v) => v.name.toLowerCase());
  const loaded = useSelector(getVisualizationsLoaded);
  const components = useSelector(getComponents);

  const [updating, setUpdating] = useState(null);
  const [filterValue, setFilterValue] = useState('');
  const [selectedVisualizations, setselectedVisualizations] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [showSettingsForm, setShowSettingsForm] = useState(false);

  useEffect(() => {
    if (site.id) {
      dispatch(requestVisualizations(site.id));
      dispatch(requestSources({ siteId: site.id, withVariables: true }));
      dispatch(requestComponents(site.id));
      dispatch(setActiveComponentId(null));
    }
  }, [site.id]);

  useEffect(() => {
    setselectedVisualizations([]);
  }, [visualizations]);

  useEventListener('keyup', (e) => {
    if (e.keyCode === 27) {
      setFilterValue('');
    }
  });

  const preFilteredVisualizations = useMemo(
    () => visualizations.filter((v) => v.id && !v.id.includes('temporary')),
    [visualizations]
  );
  const filteredVisualizations = useMemo(() => {
    const filters = filterValue.trim().split(' ');
    setAllSelected(false);
    const filteredVals = preFilteredVisualizations
      .filter((v) =>
        filters.every(
          (filter) =>
            v.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
            v.type.toLowerCase().indexOf(filter.toLowerCase()) !== -1
        )
      )
      .map((viz) => {
        viz.selected = selectedVisualizations.map((v) => v.id).includes(viz.id);
        const compIds = (viz.relations || [])
          .filter((rel) => rel.entity_type && rel.entity_type.includes('component'))
          .map((rel) => rel.entity_id);
        const relatedComponents = compIds.map((id) => components.find((c) => c.id === id));
        return { ...viz, components: relatedComponents };
      });
    if (
      filteredVals.length > 0 &&
      filteredVals.filter((v) => v.selected).length === filteredVals.length
    )
      setAllSelected(true);
    setselectedVisualizations(filteredVals.filter((v) => v.selected));
    return filteredVals;
  }, [filterValue, preFilteredVisualizations, components]);

  const menuOptions = Array(filteredVisualizations?.length)
    .fill()
    .map((_, i) => i + 1);
  menuOptions.unshift('None');

  const toggleAllSelected = () => {
    if (allSelected) {
      filteredVisualizations.map((v) => (v.selected = false));
      setselectedVisualizations([]);
    } else {
      filteredVisualizations.map((v) => (v.selected = true));
      setselectedVisualizations(filteredVisualizations);
    }
    setAllSelected(!allSelected);
  };

  const handleOnClickCreate = () => {
    const tempId = `temporary-${Date.now()}`;
    dispatch(
      upsertVisualization(site.id, {
        id: tempId,
        site: site.id,
        org: site.org,
        name: 'New visualization',
        type: 'graph',
        relations: [],
        variables: [],
        configuration: {},
        isTemplate: false,
      })
    );
    setUpdating(tempId);
  };

  const handleCloseSettingsModal = useCallback(
    () => setShowSettingsForm(false),
    [setShowSettingsForm]
  );

  const handleSubmitSettingsForm = useCallback(({ priority }) => {
    const update = {
      ids: selectedVisualizations.map((v) => v.id),
      priority,
    };
    dispatch(bulkUpsertVisualization(update, site.id));
    filteredVisualizations.map((v) => (v.selected = false));
    setselectedVisualizations([]);
    setShowSettingsForm(false);
  });

  const handleCloseVisulizationClick = () => {
    setUpdating(null);
    dispatch(resetVisualizations());
  };

  const wizClass = wizardType ? `--${wizardType}` : '';

  const onSelectVisualization = (visualization) => {
    const index = filteredVisualizations.findIndex((s) => s.id === visualization.id);
    filteredVisualizations[index].selected = !filteredVisualizations[index].selected;

    if (selectedVisualizations.find((f) => f.id === visualization.id)) {
      setselectedVisualizations(selectedVisualizations.filter((f) => f.id !== visualization.id));
    } else {
      setselectedVisualizations([...selectedVisualizations, visualization]);
    }
    if (filteredVisualizations.filter((v) => v.selected).length === filteredVisualizations.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  };

  const getListItemColumns = (visualization) => {
    const cols = [
      <div className="table-cell select-column">
        <Button design="text">
          <Icon
            className={`checkbox ${visualization.selected ? 'checked' : ''}`}
            icon={`${visualization.selected ? 'check-box' : 'check-box-outline-blank'}`}
            size="s"
            onClick={(e) => {
              e.stopPropagation();
              onSelectVisualization(visualization);
            }}
          />
        </Button>
      </div>,
      <>
        {visualization.type ? (
          <Icon
            icon={vizMappings.find((m) => m.type === visualization.type).icon}
            size="s"
          />
        ) : null}
        <div className="ellipsed-text">{visualization.name}</div>
      </>,
      <div className="ellipsed-text">{visualization.type}</div>,
      <DropdownMenu
        trigger={
          <DropdownTrigger
            icon="abb-down"
            className="dropdown-font"
            label={
              visualization.configuration.priority === 'none'
                ? 'None'
                : visualization.configuration.priority
            }
            slim
          />
        }
        menuOptions={menuOptions.map((t) => ({
          label: t,
          value: t === 'None' ? 'none' : t,
          onSelect: (selection) =>
            dispatch(
              saveVisualization({
                ...visualization,
                configuration: { ...visualization.configuration, priority: selection.value },
              })
            ),
        }))}
        styles={{ container: { width: 'auto', minWidth: '6rem' } }}
      />,
      <div className="blocked-item-container">
        {visualization.components.map((c, i) => (
          <div
            key={`comp-${i}`}
            className="blocked-item"
          >
            {`${c?.name} (${c?.itemDesignation})`}
          </div>
        ))}
      </div>,
    ];

    return cols;
  };

  const headers = [
    {}, // For select column
    { sortKey: 'name', label: 'Visualization name' },
    { sortKey: 'type', label: 'Type' },
    { sortKey: 'priority', label: 'Priority' },
    { sortKey: undefined, label: 'Linked components' },
  ];

  const headerColumns = headers.map((h) => {
    if (!h.sortKey) {
      return <div>{h.label}</div>;
    }
    return (
      <Button
        iconPosition="right"
        activity="secondary"
      >
        {h.label}
      </Button>
    );
  });

  return (
    <>
      <Heading
        contentLeft={
          <>
            <div className="title">Visualizations</div>
            {preFilteredVisualizations.length !== 0 && (
              <Input
                type="text"
                onChange={(e) => setFilterValue(e.target.value)}
                value={filterValue}
                placeholder="Search..."
              />
            )}
          </>
        }
        contentRight={
          <Button
            className={`button-create${wizardType ? wizClass : ''}`}
            onClick={handleOnClickCreate}
            icon={<Icon icon="abb-plus" />}
          >
            New visualization
          </Button>
        }
      />

      {loaded && site ? (
        <div className="manage-visualizations-body custom-thin-scrollbar">
          <p className="visualizations">
            {`Manage visualizations (${filteredVisualizations?.length})`}
          </p>

          <div className="list-selection-menu">
            <label
              className="select-all-label"
              onClick={toggleAllSelected}
            >
              <Icon
                className={`select-all ${allSelected ? 'checked' : ''}`}
                icon={`${allSelected ? 'check-box' : 'check-box-outline-blank'}`}
                size="s"
              />
              Select all
            </label>
            {selectedVisualizations.length > 0 && (
              <Button
                onClick={() => setShowSettingsForm(true)}
                activity="secondary"
                icon={<Icon icon="abb-settings" />}
                tooltip="Settings"
                slim={true}
              />
            )}
          </div>
          <div className="list-container manage-visualization-body custom-thin-scrollbar">
            <ListItem
              isHeader
              columns={headerColumns}
              columnWidths={{ type: 'rem', widths: [3.5, 35, 10, 10, 'fill'] }}
            />
            {filteredVisualizations.map((v) => (
              <ListItem
                key={v.id}
                entity={`Signal (${v.name})`}
                item={v}
                columns={getListItemColumns(v)}
                columnWidths={{ type: 'rem', widths: [3.5, 35, 10, 10, 'fill'] }}
                customEdit
                onEdit={() => setUpdating(v.id)}
                onDelete={() => dispatch(requestDeleteVisualization(v.id))}
                confirmationDialogTitle={'Delete visualization'}
                confimationDialogBody={
                  <>
                    <p style={{ paddingTop: '1.5rem' }}>
                      Are you sure you want to remove <b>{v.name}</b>?
                    </p>
                  </>
                }
              ></ListItem>
            ))}
          </div>
        </div>
      ) : (
        <div className="loading-container">
          <Spinner
            size="m"
            className="spinner"
          />
        </div>
      )}

      {updating && (
        <VisualizationEditor
          visualizationId={updating}
          visualizationNames={visualizationNames}
          onClose={handleCloseVisulizationClick}
        />
      )}

      {showSettingsForm && selectedVisualizations?.length > 0 && (
        <VisualizationSettingsModal
          visLength={preFilteredVisualizations.length}
          onCloseModal={handleCloseSettingsModal}
          onSubmit={handleSubmitSettingsForm}
        ></VisualizationSettingsModal>
      )}
    </>
  );
};

export default VisualizationView;
