import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { Button, Icon } from '@iq/react-components';

import {
  createSite,
  lookupSite,
  uploadSitesExcelFile,
  getCreatedSite,
  getSites,
} from '../../bundles/sites';
import { getLayouts } from '../../bundles/layouts';
import Loader from '../Loader';
import SiteCreateModal from './components/SiteCreateModal';
import SitesImportModal from './components/SitesImportModal';
import { DropdownMenu } from '../DropdownMenu';

import { removeSiteSpecificData } from '../../utils';

const SiteCreateView = React.memo(({ siteParams = {}, realm }) => {
  const history = useHistory();
  const { integrationInfo = {}, properties, thirdPartyId } = siteParams;
  const { isIntegration = false, name: integrationName = '' } = integrationInfo;
  const [displayAddSiteModal, setDisplayAddSiteModal] = useState(isIntegration);

  const [displayImportSitesModal, setDisplayImportSitesModal] = useState(false);
  const [creatingSite, setCreatingSite] = useState(false);
  const [checkingExistingSite, setCheckingExistingSite] = useState(true);

  const dispatch = useDispatch();
  const createdSite = useSelector(getCreatedSite);
  const layouts = useSelector(getLayouts);
  const sites = useSelector(getSites);

  const defaultLayout = siteParams.defaultLayout || {
    pages: [
      {
        id: `page-${uuidv4()}`,
        title: 'Overview',
        path: 'overview',
        sections: [],
        icon: 'abb-home',
        viewer: {
          display: true,
          showFullHeight: true,
          tooltipOnHover: false,
          showAreaObjects: false,
          focusActiveComponent: false,
          isolate: true,
          forceErrorPins: false,
          selectionType: 1,
          renderComponents: 1,
          highlightComponents: 2,
          overlayComponents: 0,
          showPushPins: 0,
          renderActiveSite: true,
          renderActiveComponent: false,
          highlightActiveSite: false,
          highlightActiveComponent: true,
          overlayActiveSite: false,
          overlayActiveComponent: false,
          pushpinsForActiveSite: false,
          pushpinsForActiveComponent: false,
          panels: [
            {
              displayTitle: false,
              allowFullScreen: false,
              title: 'Components',
              type: 'components',
              options: {
                withToolbar: true,
                startingDepth: 1,
                endingDepth: 0,
                expandedDepth: 2,
                displayStatus: true,
                flatList: false,
              },
            },
          ],
          toolbarOptions: ['measure', 'firstperson', 'settings'],
        },
      },
    ],
    type: 'pt',
  };

  const layoutOptions = useMemo(
    () =>
      Object.keys(layouts)
        .filter((siteId) => sites.findIndex((s) => s.id === siteId && s.isTemplate) >= 0)
        .map((siteId) => ({
          key: siteId,
          siteName: (sites.find(({ id }) => id === siteId) || {}).name,
        })),
    [sites, layouts]
  );

  const transformErrors = (errors) =>
    errors.map((error) => {
      /* eslint-disable no-return-assign, no-param-reassign */
      switch (error.name) {
        case 'format':
          if (error.property === '.org')
            error.message = 'Organization should only contain alpha-numeric characters (no spaces)';
          break;
        case 'maximum':
        case 'minimum':
          if (error.property === '.lat') error.message = 'Enter valid Latitude';
          else if (error.property === '.lng') error.message = 'Enter valid Longitude';
          break;
        case 'required':
          {
            let property = error.property.includes('.') ? error.property.slice(1) : error.property;
            if (property.indexOf('location') === 0) {
              [, property] = property.split('.');
            }
            if (property === 'lat') error.message = 'Enter Latitude';
            else if (property === 'lng') error.message = 'Enter Longitude';
            else error.message = `Enter ${property.slice(0, 1).toUpperCase() + property.slice(1)}`;
          }
          break;
        default:
      }
      /* eslint-enable no-return-assign, no-param-reassign */
      return error;
    });

  const onCreate = (data) => {
    layouts.default = defaultLayout;
    setCreatingSite(true);
    const { template, ...siteData } = data;
    const { id, ...layoutCopy } = layouts[template];
    const siteLayout = template === 'default' ? defaultLayout : layoutCopy;

    removeSiteSpecificData(siteLayout);
    dispatch(
      createSite(
        {
          ...siteData,
          isIntegration,
          integrationName,
          org: realm,
          template,
          properties,
        },
        siteLayout,
        () => setCreatingSite(false)
      )
    );

    setDisplayAddSiteModal(false);
  };

  const onClose = useCallback(() => setDisplayAddSiteModal(false), []);

  const onImportSitesExcel = (file) => {
    setDisplayImportSitesModal(false);
    dispatch(uploadSitesExcelFile(file, realm));
  };

  async function redirectIfSiteExists() {
    const type = (integrationName || '')?.toLowerCase();

    if (thirdPartyId && type) {
      const relative = true;
      const data = await lookupSite(type, thirdPartyId, {
        relative,
      });

      if (data?.exists) {
        history.push(data.url);
      }
    }

    setCheckingExistingSite(false);
  }

  useEffect(() => {
    redirectIfSiteExists();
  }, []);

  if (createdSite && layouts && layouts[createdSite.id]) {
    return <Redirect to={`/sites/${createdSite.id}/`}></Redirect>;
  }

  const trigger = (
    <Button
      icon={<Icon icon="abb-caret-down" />}
      iconPosition="right"
    >
      Add site
    </Button>
  );

  return (
    <div className="site-create-view-component">
      {checkingExistingSite ? (
        <Loader text="Checking for existing site ..." />
      ) : (
        <>
          {creatingSite ? (
            <Loader
              text="Creating site ..."
              overlay
            />
          ) : (
            !isIntegration && (
              <>
                <DropdownMenu
                  trigger={trigger}
                  triggerSize="l"
                  menuOptions={[
                    {
                      component: <div> Add a site </div>,
                      onSelect: () => setDisplayAddSiteModal(true),
                    },
                    {
                      component: <div> Add sites in bulk </div>,
                      onSelect: () => setDisplayImportSitesModal(true),
                    },
                  ]}
                  className="site-item-menu"
                  tooltip="Add site"
                  tooltipPosition="bottom-start"
                />
              </>
            )
          )}
          {displayAddSiteModal && (
            <SiteCreateModal
              siteParams={siteParams}
              layouts={layoutOptions}
              onSubmit={onCreate}
              transformErrors={transformErrors}
              onCloseModal={onClose}
            />
          )}
          {displayImportSitesModal && (
            <SitesImportModal
              layouts={layoutOptions}
              onSubmit={onImportSitesExcel}
              transformErrors={transformErrors}
              onCloseModal={() => setDisplayImportSitesModal(false)}
            />
          )}
        </>
      )}
    </div>
  );
});

export default SiteCreateView;
