import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, useAuth } from '@iq/react-components';
import { PersonSharp as AvatarIcon, ExitToApp as LogoutIcon } from '@mui/icons-material';
import AppContext from '../../AppContext';
import ThemeSwitch from '../ThemeSwitch';

const UserInfo = () => {
  const { theme, setTheme } = useContext(AppContext);
  const darkMode = theme === 'dark';
  const ref = useRef(null);
  const [showPanel, setShowPanel] = useState(false);

  const {
    profile: { name, email },
    logOut,
  } = useAuth();

  function handleOutsideClick(e) {
    if (ref.current && !ref.current.contains(e.target)) {
      setShowPanel(false);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleToggleOpen = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowPanel((prev) => !prev);
  };

  const onDarkModeToggle = () => {
    const next = !darkMode;
    setTheme(next ? 'dark' : 'light');
  };

  return (
    <div ref={ref}>
      <Button
        design="text"
        tooltip="User Info"
        className="user-avatar-button"
        onClick={handleToggleOpen}
      >
        <AvatarIcon sx={{ fontSize: '1.6rem' }} />
      </Button>

      {showPanel && (
        <div className="user-info-panel">
          <div className={'user-info-section'}>
            <div className="user-info">
              <div className="user-avatar">
                <Button
                  design="text"
                  className={'user-avatar-button'}
                >
                  <div>
                    <AvatarIcon sx={{ fontSize: '2.5rem' }} />
                  </div>
                </Button>
              </div>
              <div className="user-name">
                <span>{name || email}</span>
              </div>
              {email && (
                <div className="user-email">
                  <span>{email}</span>
                </div>
              )}
            </div>
            <div className="user-info-actions">
              <div
                className="logout-button"
                onClick={logOut}
              >
                <LogoutIcon sx={{ fontSize: '1.6rem' }} />
                <span>Sign off</span>
              </div>

              <ThemeSwitch
                onChange={onDarkModeToggle}
                nightmode={darkMode}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserInfo;
