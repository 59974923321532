import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { contrastChart, Spinner, Button, Icon } from '@iq/react-components';
import { ResponsiveContainer, PieChart, Pie, LabelList } from 'recharts';

import Loader from '../../../Loader';
import { getVariableById } from '../../../../bundles/sources';
import { useSubscription } from '../../../../bundles/visualizations';
import { format } from '../../../../units';
import { useClientSize } from '../../../../utils';
import { THRESHOLD_TYPES } from '../../../../constants';
import { lightenDarkenColor } from '../../../../colors';

const alignValue = (value, min, max) => Math.max(min, Math.min(value, max));

const GaugeVisualization = ({ visualization, panelId }) => {
  const {
    latestValues: values,
    initialLoaded,
    loading,
  } = useSubscription(visualization.id, panelId);
  const [firstVar] = values || [];
  const { variable: variableId, value: firstVarValue = [] } = firstVar || {};
  const sourceVar = useSelector((state) => getVariableById(state, variableId));
  const [{ height }, clientRef] = useClientSize();
  const { configuration = {} } = visualization;
  const { thresholds = {}, min = 0, max = 100 } = configuration;
  const currentThresholdKey = THRESHOLD_TYPES[thresholds.thresholdType]?.key || '';
  const isAreaThresholds = currentThresholdKey === THRESHOLD_TYPES.area.key;

  const getThresholdValue = (item) => {
    if (isAreaThresholds) {
      return item.min;
    }
    return item.value;
  };

  const sortedThresholds = useMemo(() => {
    if (thresholds[currentThresholdKey]) {
      return thresholds[currentThresholdKey]
        .slice()
        .sort((a, b) => getThresholdValue(a) - getThresholdValue(b));
    }
    return [];
  }, [thresholds, currentThresholdKey]);

  const [value, quality] = useMemo(() => {
    if (initialLoaded) {
      const [, lastValue, lastValueQuality] = firstVarValue;
      if (lastValue || lastValue === 0) {
        const currValue = Number.isNaN(lastValue) ? null : alignValue(lastValue, min, max);
        return [currValue, lastValueQuality];
      }
    }
    return [null, 0];
  }, [firstVarValue, initialLoaded, min, max]);

  const thresholdsPercentageWidth = 0.015;
  const thresholdWidth = parseInt(max * thresholdsPercentageWidth, 8);

  /**
   * Create all threshold slices to fill the gauge.
   */
  const { slices } = sortedThresholds.reduce(
    (acc, cur, i) => {
      if (acc.runningTotal >= max) return acc;
      let thWidth = thresholdWidth;

      if (isAreaThresholds) {
        const diff = cur.max - cur.min;
        const percentageOfMax = diff / max;
        const trueWidth = max * percentageOfMax;
        thWidth = trueWidth > max ? max : trueWidth;
      }

      const thresholdFill = lightenDarkenColor(cur.fill, -10);

      let buffertWidth = getThresholdValue(cur) - acc.runningTotal;

      if (isAreaThresholds) {
        const diff = getThresholdValue(cur) - acc.runningTotal;
        const percentageOfMax = diff / max;
        buffertWidth = max * percentageOfMax;
      }

      let addSlices = getThresholdValue(cur) - thWidth <= max;
      if (isAreaThresholds) {
        addSlices = getThresholdValue(cur) + thWidth <= max && cur.min < cur.max;
      }

      if (addSlices) {
        // Add buffer and threshold only if the threshold is less than max
        acc.slices.push({ value: buffertWidth, fill: 'transparent' });
        acc.slices.push({ ...cur, fill: thresholdFill, value: thWidth });
        acc.runningTotal += buffertWidth + thWidth;
      }

      // Add a last buffer slice if neaded, to fill the rest of the gauge
      if (i === sortedThresholds.length - 1 && acc.runningTotal < max) {
        buffertWidth = Math.max(
          acc.slices.reduce((innerAcc, curr) => innerAcc - curr.value, max),
          0
        );

        if (isAreaThresholds) {
          buffertWidth = max - acc.runningTotal;
        }

        acc.slices.push({ value: buffertWidth, fill: 'transparent' });
      }

      return acc;
    },
    { runningTotal: 0, slices: [] }
  );

  const [fill, description] = sortedThresholds.reduce(
    (acc, item) => {
      if (isAreaThresholds) {
        if (value >= item.min && value <= item.max && item.min < item.max && item.max <= max) {
          return [item.fill, item.description];
        }
        return acc;
      }
      return value >= getThresholdValue(item) ? [item.fill, item.description] : acc;
    },
    ['grey', '']
  );

  const data = [
    { value, fill, fillOpacity: isAreaThresholds ? '100%' : '68%' },
    { value: max - min - value, fill: 'transparent' },
  ];

  const displayUnit =
    configuration.unit === 'variable-default' ? sourceVar?.unit || '' : configuration.unit;
  const { value: formattedValue, unit } = format(displayUnit, value, configuration.decimals);

  const renderArrow = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      viewBox: { cx, cy, innerRadius, startAngle },
      value: valLocal,
    } = props;
    const sin = Math.sin(-RADIAN * startAngle);
    const cos = Math.cos(-RADIAN * startAngle);
    const sx = cx - innerRadius * cos;
    const sy = cy + innerRadius * sin;
    const rotation = -100 + (valLocal / max) * 200;

    return (
      <g
        className="gauge-value-marker"
        transform={`rotate(${rotation},${sx},${sy})`}
      >
        <polygon
          points={`${sx},${sy} ${sx + 5},${sy + 10} ${sx - 5},${sy + 10}`}
          stroke="3"
          fill="black"
        />
      </g>
    );
  };

  const vizHeight = height - 28; // less header
  const loaderHeight = vizHeight - 28; // less loader padding

  // only invalidate if quality 2 (invalid) or 3 (questionable)
  const isDataInvalid = quality > 1;

  return (
    <div
      className={`gauge-visualization-component ${isDataInvalid ? 'invalid-data' : ''}`}
      ref={clientRef}
    >
      <div className="gauge-header">
        <div className="gauge-title">
          <span className="title">{configuration.title || '\u00A0'}</span>
          {isDataInvalid ? (
            <Button
              tooltip="Invalid signal value"
              design="text"
            >
              <Icon
                icon="abb-help-circle-2"
                size="s"
                color="#FF7300"
              />
            </Button>
          ) : null}
        </div>
        {initialLoaded && loading && (
          <Spinner
            size="s"
            className="viz-loading-spinner"
          />
        )}
      </div>
      {!initialLoaded && loading ? (
        <Loader
          text="Loading ..."
          height={loaderHeight}
        />
      ) : (
        <div className="gauge-wrapper">
          <ResponsiveContainer
            width="100%"
            height={vizHeight}
          >
            <PieChart>
              {/* This is the arrow marker and a transparent bar */}

              <Pie
                startAngle={((max - data[1].value) / max) * 200 - 10}
                endAngle={-10}
                data={data}
                dataKey="value"
                innerRadius="90%"
                outerRadius="100%"
                fill="transparent"
                stroke={0}
                isAnimationActive={false}
              >
                <LabelList content={renderArrow} />
              </Pie>

              {/*
                  Renders the default background bar
                */}

              <Pie
                startAngle={190}
                endAngle={-10}
                data={[{ fill: '#D9D9D9', value: max }]}
                innerRadius="90%"
                outerRadius="100%"
                dataKey="value"
                stroke={0}
                isAnimationActive={false}
              />

              {/* This is the value bar */}

              {currentThresholdKey !== THRESHOLD_TYPES.area.key ? (
                <Pie
                  startAngle={190}
                  endAngle={-10}
                  data={data}
                  dataKey="value"
                  innerRadius="90%"
                  outerRadius="100%"
                  stroke={0}
                  isAnimationActive={false}
                />
              ) : null}

              {/* This is the thresholds */}

              <Pie
                startAngle={190}
                endAngle={-10}
                data={slices}
                dataKey="value"
                innerRadius="90%"
                outerRadius={isAreaThresholds ? '100%' : '104%'}
                fill={contrastChart[0]}
                stroke={0}
                isAnimationActive={false}
              />
            </PieChart>
          </ResponsiveContainer>
          <div className="value-wrapper">
            <div className="value-wrapper--value">
              <div
                style={{ color: fill }}
                className="value"
              >
                {initialLoaded ? formattedValue : '-'}
              </div>
              <div
                style={{ color: fill }}
                className="unit"
              >
                {configuration.unitLabel ? configuration.unitLabel : unit}
              </div>
            </div>
            {description && <p style={{ color: fill }}>{description}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default GaugeVisualization;
