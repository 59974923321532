.hv-tab-content .list-container {
  height: 100%;
}
.hv-tab-content .integration-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.hv-tab-content .integration-name div:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.hv-tab-content .integration-name .blocked-item {
  margin: 0 0 0 1rem;
}
.hv-tab-content .integration-name .dropdown-menu--trigger {
  border-radius: 0.3rem;
  padding: 0.1rem 0.5rem;
  font-size: 0.9rem;
  white-space: nowrap;
  display: flex;
  margin: 0;
}
.hv-tab-content .integration-name .dropdown-menu--trigger-label {
  text-transform: capitalize;
}

.transfer-button-spinner {
  width: 4rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvVGVuYW50QWRtaW5WaWV3L2NvbXBvbmVudHMvVGVuYW50QWRtaW5JbnRlZ3JhdGlvblZpZXcvU2VydmljZUFjY291bnRzVGFiIiwic291cmNlcyI6WyJTZXJ2aWNlQWNjb3VudHNUYWIuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFHRTtFQUNFOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNFOzs7QUFNUjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCAnQGlxL3JlYWN0LWNvbXBvbmVudHMvZGlzdC9jb2xvcnMuc2Nzcyc7XG5cbi5odi10YWItY29udGVudCB7XG4gIC5saXN0LWNvbnRhaW5lciB7XG4gICAgaGVpZ2h0OiAxMDAlO1xuICB9XG5cbiAgLmludGVncmF0aW9uLW5hbWUge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47XG4gICAgd2lkdGg6IDEwMCU7XG5cbiAgICBkaXY6Zmlyc3QtY2hpbGQge1xuICAgICAgb3ZlcmZsb3c6IGhpZGRlbjtcbiAgICAgIHRleHQtb3ZlcmZsb3c6IGVsbGlwc2lzO1xuICAgICAgd2hpdGUtc3BhY2U6IG5vd3JhcDtcbiAgICB9XG5cbiAgICAuYmxvY2tlZC1pdGVtIHtcbiAgICAgIG1hcmdpbjogMCAwIDAgMXJlbTtcbiAgICB9XG5cbiAgICAuZHJvcGRvd24tbWVudS0tdHJpZ2dlciB7XG4gICAgICBib3JkZXItcmFkaXVzOiAwLjNyZW07XG4gICAgICBwYWRkaW5nOiAwLjFyZW0gMC41cmVtO1xuICAgICAgZm9udC1zaXplOiAwLjlyZW07XG4gICAgICB3aGl0ZS1zcGFjZTogbm93cmFwO1xuICAgICAgZGlzcGxheTogZmxleDtcbiAgICAgIG1hcmdpbjogMDtcblxuICAgICAgJi1sYWJlbCB7XG4gICAgICAgIHRleHQtdHJhbnNmb3JtOiBjYXBpdGFsaXplO1xuICAgICAgfVxuICAgIH1cbiAgfVxufVxuXG4udHJhbnNmZXItYnV0dG9uLXNwaW5uZXIge1xuICB3aWR0aDogNHJlbTtcbn1cbiJdfQ== */