.corevisualization-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}
.corevisualization-component .viz-loading-spinner {
  margin-right: 1.5rem;
}

.height-medium-small .metric-visualization-component .wrapper .value-wrapper .value,
.height-small .metric-visualization-component .wrapper .value-wrapper .value {
  font-size: 1.4rem;
}
.height-medium-small .metric-visualization-component .wrapper .label,
.height-small .metric-visualization-component .wrapper .label {
  top: 0.5rem;
}
.height-medium-small .metric-visualization-component .value-wrapper,
.height-small .metric-visualization-component .value-wrapper {
  line-height: 1.6rem;
}
.height-medium-small .gauge-visualization-component .gauge-wrapper .value-wrapper .value-wrapper--value .value,
.height-small .gauge-visualization-component .gauge-wrapper .value-wrapper .value-wrapper--value .value {
  font-size: 1.4rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvVmlzdWFsaXphdGlvbiIsInNvdXJjZXMiOlsiVmlzdWFsaXphdGlvbi5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7OztBQU9BO0FBQUE7RUFDRTs7QUFJQTtBQUFBO0VBQ0U7O0FBSUo7QUFBQTtFQUNFOztBQUlGO0FBQUE7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgJ0BpcS9yZWFjdC1jb21wb25lbnRzL2Rpc3QvY29sb3JzLnNjc3MnO1xuXG4uY29yZXZpc3VhbGl6YXRpb24tY29tcG9uZW50IHtcbiAgd2lkdGg6IDEwMCU7XG4gIGhlaWdodDogMTAwJTtcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAganVzdGlmeS1pdGVtczogY2VudGVyO1xuXG4gIC52aXotbG9hZGluZy1zcGlubmVyIHtcbiAgICBtYXJnaW4tcmlnaHQ6IDEuNXJlbTtcbiAgfVxufVxuXG4uaGVpZ2h0LW1lZGl1bS1zbWFsbCxcbi5oZWlnaHQtc21hbGwge1xuICAubWV0cmljLXZpc3VhbGl6YXRpb24tY29tcG9uZW50IHtcbiAgICAud3JhcHBlciAudmFsdWUtd3JhcHBlciAudmFsdWUge1xuICAgICAgZm9udC1zaXplOiAxLjRyZW07XG4gICAgfVxuXG4gICAgLndyYXBwZXIge1xuICAgICAgLmxhYmVsIHtcbiAgICAgICAgdG9wOiAwLjVyZW07XG4gICAgICB9XG4gICAgfVxuXG4gICAgLnZhbHVlLXdyYXBwZXIge1xuICAgICAgbGluZS1oZWlnaHQ6IDEuNnJlbTtcbiAgICB9XG4gIH1cbiAgLmdhdWdlLXZpc3VhbGl6YXRpb24tY29tcG9uZW50IHtcbiAgICAuZ2F1Z2Utd3JhcHBlciAudmFsdWUtd3JhcHBlciAudmFsdWUtd3JhcHBlci0tdmFsdWUgLnZhbHVlIHtcbiAgICAgIGZvbnQtc2l6ZTogMS40cmVtO1xuICAgIH1cbiAgfVxufVxuIl19 */