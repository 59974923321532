import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '@iq/react-components';

import { getActiveComponent, getSiteComponent } from '../../../bundles/components';

const ComponentPropertiesPanel = ({
  showComponentName = true,
  showCategories = true,
  categoriesFilter = [],
  showSiteIfNoActiveComponent = true,
}) => {
  const siteComponent = useSelector(getSiteComponent);
  let activeComponent = useSelector(getActiveComponent);

  if (typeof activeComponent === 'undefined' && showSiteIfNoActiveComponent) {
    activeComponent = siteComponent;
  }

  const { id, name, itemDesignation, icon, properties = [] } = activeComponent || {};

  const filteredProperties = useMemo(() => {
    const actualCategories = categoriesFilter.filter((c) => c !== '');
    if (actualCategories.length === 0) {
      return properties;
    }

    return properties.filter((p) =>
      actualCategories.some((c) => c.toLowerCase() === p.category.toLowerCase())
    );
  }, [properties, categoriesFilter]);

  const categorizeProperties = useCallback(() => {
    if (filteredProperties.length === 0) {
      return {};
    }

    return filteredProperties.reduce((acc, cur) => {
      acc[cur.category] = [...(acc[cur.category] || []), cur];
      return acc;
    }, {});
  }, [filteredProperties]);

  const sortByNameOrAttr = (a, b) => {
    const aKey = a.name || a.attribute;
    const bKey = b.name || b.attribute;
    if (aKey < bKey) return -1;
    if (aKey > bKey) return 1;
    return 0;
  };

  const renderWithCategories = useCallback(() => {
    const categorizedProperties = categorizeProperties();
    return Object.keys(categorizedProperties)
      .toSorted((a, b) => a.localeCompare(b))
      .map((cat) => (
        <table key={cat}>
          <thead>
            <tr>
              <th>{cat}</th>
            </tr>
          </thead>
          <tbody>
            {categorizedProperties[cat].sort(sortByNameOrAttr).map((p, i) => (
              <tr
                key={`${p.attribute}_${p.value}_${i}`}
                className="property"
              >
                <td>{p.name || p.attribute}</td>
                <td>{p.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ));
  }, [categorizeProperties, showCategories]);

  const renderWithoutCategories = useCallback(
    () => (
      <table>
        <tbody>
          {filteredProperties.sort(sortByNameOrAttr).map((p, i) => (
            <tr
              key={`${p.attribute}_${p.value}_${i}`}
              className="property"
            >
              <td>{p.name || p.attribute}</td>
              <td>{p.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ),
    [filteredProperties]
  );

  const renderProperties = useCallback(() => {
    if (properties.length === 0) {
      return <div className="properties empty">No properties for selected component</div>;
    }
    if (filteredProperties.length === 0) {
      return <div className="properties empty">No properties for selected category filter</div>;
    }
    return (
      <div className="properties">
        {showCategories ? renderWithCategories() : renderWithoutCategories()}
      </div>
    );
  }, [properties, filteredProperties, showCategories]);

  if (!id) {
    return <div className="cp-panel-component empty">No component selected</div>;
  }

  return (
    <div className="cp-panel-component">
      {showComponentName && (
        <div className="component-header">
          <div>
            <div className="component-name">{name}</div>
            <div className="component-id">{itemDesignation}</div>
          </div>
          <Icon icon={icon} />
        </div>
      )}
      {renderProperties()}
    </div>
  );
};

export default ComponentPropertiesPanel;
