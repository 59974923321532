import React, { useMemo } from 'react';
import { colors, Icon, Spinner, Button } from '@iq/react-components';

import { useSubscription } from '../../../../bundles/visualizations';
import { getActiveStates } from '../../../../bundles/statesets';

const StateVisualization = ({ isPreview = false, visualization, panelId }) => {
  const { id, configuration = {}, variables } = visualization;
  const { loading, latestValues: values } = useSubscription(id, panelId);

  const statefulVarsWithValues = useMemo(
    () =>
      variables
        .filter((v) => v.stateset)
        .map((v) => {
          const varData = values?.find((val) => val.variable === v.id);
          const lastValue = varData?.value.at(-2) || null;
          const lastValueQuality = varData?.value.at(-1) || null;

          return { ...v, value: lastValue, quality: lastValueQuality };
        }),
    [values, variables]
  );
  const activeStates = getActiveStates(statefulVarsWithValues);

  // only invalidate if quality 2 (invalid) or 3 (questionable)
  const isDataInvalid = statefulVarsWithValues.some((value) => value.quality > 1);

  const getIcon = (states) => {
    if (!states.length) {
      return (
        <Icon
          icon="status"
          size="m"
          style={{ color: colors.Grey50 }}
        />
      );
    }
    if (states.length === 1) {
      return (
        <>
          <Icon
            icon="status"
            size="m"
            style={{ color: states[0].color }}
          />
          <p className="state-text">{states[0].text}</p>
        </>
      );
    }
    return (
      <Icon
        icon="abb-help-circle-1"
        size="m"
        style={{ color: colors.StatusRed }}
      />
    );
  };

  return (
    <div
      className={`state-visualization-component ${isPreview ? 'preview' : 'normal'} ${
        isDataInvalid ? 'invalid-data' : ''
      }`}
    >
      <div className="wrapper">
        {loading ? <Spinner /> : getIcon(activeStates)}
        <div className="label">
          {configuration.label}
          {isDataInvalid ? (
            <Button
              tooltip="Invalid state"
              design="text"
            >
              <Icon
                icon="abb-help-circle-2"
                size="m"
                color="#FF7300"
              />
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default StateVisualization;
