/* eslint-disable no-nested-ternary */
import React from 'react';
import { Icon } from '@iq/react-components';
import {
  usePanelDateDomain,
  useSelectedDateDomain,
  useActiveTimelineData,
} from './EventTimelineState';

import { USER_EVENTS } from '../../../constants';

const EventLegends = ({ fullWidth, type = 'list', showLegend = true }) => {
  const selectedDateDomain = useSelectedDateDomain();
  const panelDateDomain = usePanelDateDomain();
  const currentPanelDateDomain = selectedDateDomain || panelDateDomain;
  const { response } = useActiveTimelineData();

  const iotEvent = [
    {
      type: 'Normal/Minor/Other',
      color: '#3B6BF6',
    },
    {
      type: 'Warning/Severe',
      color: '#E9E34A',
    },
    {
      type: 'Emergency',
      color: '#F17679',
    },
  ];
  const getNumOfEvents = () => {
    const events = response?.userEvents.length || 0;
    const bucketEvents = response?.iotBuckets.reduce((prev, curr) => prev + curr.numEvents, 0) || 0;
    return {
      user: events,
      iot: bucketEvents,
      list: events + bucketEvents,
    }[type];
  };

  const limitedUserEvents = response?.limitedUserEvents;

  const numberOfEvents = getNumOfEvents();
  const singleEvent = numberOfEvents === 1;
  const multipleEvents = numberOfEvents > 1;

  const limitedEventsInfo = (viewType) => (
    <div className={`limited-event-info`}>
      <p className="info-text">{numberOfEvents} events - not all visible</p>
      <div className={`limited-event-info-trigger ${viewType}`}>
        <Icon
          icon="abb-help-circle-1"
          className="event-info-icon"
          size="s"
        />
      </div>
      <div className="limited-event-info-tooltip">
        <p>
          {
            'A maximum of 100 unmodified events per recurring event series is shown for the selected time range – zoom in to see more events.'
          }
        </p>
      </div>
    </div>
  );

  const eventInfo = () => {
    if (multipleEvents) {
      return `${numberOfEvents} events`;
    }
    if (singleEvent) {
      return `${numberOfEvents} event`;
    }
    return 'no events';
  };

  const shouldWarnLimitedEvents = multipleEvents && type !== 'iot' && limitedUserEvents;
  if (currentPanelDateDomain.length) {
    return (
      <div className={`event-timeline-info-header ${!fullWidth ? 'over-chart' : 'over-list'}`}>
        {showLegend ? (
          <div className={`event-timeline-legend ${type}`}>
            <ul>
              {type === 'user'
                ? USER_EVENTS.map((eventMeta, i) => {
                    const key = `${eventMeta.type}-${i}`;
                    return (
                      <li
                        key={key}
                        className="graph-legend-item"
                      >
                        <svg
                          width="12"
                          height="12"
                        >
                          <circle
                            r="5"
                            cx="6"
                            cy="6"
                            fill={eventMeta.color}
                            stroke={eventMeta.border}
                          ></circle>
                        </svg>
                        {eventMeta.type}
                      </li>
                    );
                  })
                : iotEvent.map((eventMeta, i) => {
                    const key = `${eventMeta.type}-${i}`;
                    return (
                      <li
                        key={key}
                        className="graph-legend-item"
                      >
                        <svg
                          width="10"
                          height="10"
                        >
                          <rect
                            width="10"
                            height="10"
                            rx="2"
                            ry="2"
                            fill={eventMeta.color}
                          />
                        </svg>
                        {eventMeta.type}
                      </li>
                    );
                  })}
            </ul>
          </div>
        ) : null}
        <div className="event-timeline-info">
          <div className="event-timeline-info">
            {shouldWarnLimitedEvents ? limitedEventsInfo(type) : eventInfo()}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default EventLegends;
