import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Spinner } from '@iq/react-components';

import Heading from '../../../../Heading';
import ListItem from '../../../../ListItem';
import {
  transferServiceAccounts,
  getServiceAccounts,
  isLoadingServiceAccounts,
  isTransferringServiceAccounts,
} from '../../../../../bundles/auth';
import { getAllIntegrations } from '../../../../../bundles/integrations';
import getTransferSchema from '../schemas/transferSchema';
import SimpleModal from '../../../../SimpleModal';
import JSONEditor from '../../../../JSONEditor';

const withIntegrationIds = (serviceAccount) => {
  const scopes = serviceAccount.permissions['integrations/Read'] || [];
  const ids = new Set();
  scopes.forEach((scope) => {
    if (scope.includes('templateId')) {
      ids.add(scope.slice(scope.indexOf('templateId:') + 11, scope.indexOf('templateId:') + 47));
    } else if (scope.includes('integrationId')) {
      ids.add(
        scope.slice(scope.indexOf('integrationId:') + 14, scope.indexOf('integrationId:') + 50)
      );
    }
  });
  return {
    ...serviceAccount,
    integrationIds: Array.from(ids),
  };
};

const ServiceAccountsTab = () => {
  const dispatch = useDispatch();

  const [showTransferModal, setShowTransferModal] = useState(false);

  const [formData, setFormData] = useState({});
  const serviceAccounts = useSelector(getServiceAccounts);
  const serviceAccountsLoading = useSelector(isLoadingServiceAccounts);
  const serviceAccountsTransferring = useSelector(isTransferringServiceAccounts);
  const integrations = useSelector(getAllIntegrations);

  const sortedServiceAccounts = useMemo(() => {
    if (serviceAccounts && integrations) {
      const sorted = [...serviceAccounts.sort((a, b) => a.name.localeCompare(b.name))];
      return sorted.map((serviceAccount) => withIntegrationIds(serviceAccount));
    }
    return [];
  }, [serviceAccounts, integrations]);

  const schema = useMemo(() => getTransferSchema(sortedServiceAccounts), [sortedServiceAccounts]);

  const handleOnClose = () => {
    setFormData({});
    setShowTransferModal(false);
  };

  const serviceAccountHeaderColumns = [
    <div key="service-account-header-name">Service account</div>,
    <div key="service-account-header-integrations">Linked Integrations</div>,
  ];

  const getListItemColumns = (serviceAccount, index) => [
    <div
      key={`row-${index}-name`}
      className="ellipsed-text"
    >
      {serviceAccount.name}
    </div>,
    <div key={`row-${index}-integrations`}>{serviceAccount.integrationIds.length}</div>,
  ];

  const getIntegrationNames = useCallback(
    (serviceAccount) => {
      if (!serviceAccount.integrationIds) {
        return null;
      }

      return serviceAccount.integrationIds.map((id, i) => {
        const foundIntegration = integrations.find((integration) => integration.id === id);
        const name = `${foundIntegration?.name || '**Integration Removed**'} (${id})`;
        const keyPrefix = `accordion-row-${i}`;
        return [
          <div key={`${keyPrefix}-name`}></div>,
          <div
            key={`${keyPrefix}-integration-name`}
            className="integration-name"
          >
            {name}
          </div>,
        ];
      });
    },
    [integrations]
  );

  const serviceAccountList = useMemo(() => {
    return sortedServiceAccounts.map((serviceAccount, i) => (
      <ListItem
        key={serviceAccount.id}
        itemIndex={i}
        entity={`Service Account (${serviceAccount.name})`}
        item={serviceAccount}
        columns={getListItemColumns(serviceAccount, i)}
        accordionContent={getIntegrationNames(serviceAccount)}
        withActions={false}
      />
    ));
  }, [sortedServiceAccounts]);

  const handleTransferServiceAccount = ({ formData: { fromId, toId } }) => {
    dispatch(transferServiceAccounts(fromId, toId));
    setFormData({});
  };

  useEffect(() => {
    if (!serviceAccountsTransferring) {
      setShowTransferModal(false);
    }
  }, [serviceAccountsTransferring]);

  const customValidate = useCallback(
    (userInput, errors) => {
      if (
        userInput.fromId === userInput.toId &&
        Object.values(userInput).every((i) => i !== undefined)
      ) {
        errors.addError('Same service account selected');
      }
      return errors;
    },
    [sortedServiceAccounts, formData]
  );

  const serviceAccountForm = useMemo(
    () => (
      <div>
        <JSONEditor
          schema={schema}
          saveButtonText={
            serviceAccountsTransferring ? (
              <div className="transfer-button-spinner">
                <Spinner />
              </div>
            ) : (
              'Transfer'
            )
          }
          initialEditMode
          showEditButton={false}
          formData={formData}
          cancelCallback={() => handleOnClose()}
          onFormSubmit={handleTransferServiceAccount}
          customValidate={customValidate}
          editorOnly
          submitDisabled={serviceAccountsTransferring}
        />
      </div>
    ),
    [formData, serviceAccountsTransferring]
  );

  return (
    <div className="hv-tab-content">
      <Heading
        contentRight={
          <Button
            onClick={() => setShowTransferModal(true)}
            className="button-transfer"
            disabled={serviceAccountsLoading}
          >
            Transfer service account
          </Button>
        }
      />

      {serviceAccountsLoading ? (
        <div className="loading-container">
          <div className="loading-container">
            <Spinner
              size="m"
              className="spinner"
            />
          </div>
        </div>
      ) : (
        <div className="list-container">
          <ListItem
            isHeader
            columns={serviceAccountHeaderColumns}
            withActions={false}
          />
          {serviceAccountList}
        </div>
      )}

      {showTransferModal && (
        <SimpleModal
          title="Transfer service account"
          subtitle="Select service accounts to transfer permissions. This action will revoke permissions from the originating account."
          onClose={() => handleOnClose()}
          overlayCanClose={false}
        >
          {serviceAccountForm}
        </SimpleModal>
      )}
    </div>
  );
};

export default ServiceAccountsTab;
