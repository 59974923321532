import React, { useState, useEffect, useRef } from 'react';
import Form from '@rjsf/core';
import { customizeValidator } from '@rjsf/validator-ajv8';
import Ajv2019 from 'ajv/dist/2019';
import { Button, Icon } from '@iq/react-components';

import TextWidget from './widgets/TextWidget';
import TextareaWidget from './widgets/TextareaWidget';
import SelectWidget from './widgets/SelectWidget';
import IconPickerField from './fields/IconPickerField';
import CreatableField from './fields/CreateableField';
import InputWithTooltipField from './fields/InputWithTooltipField';
import DateTimeRangePickerField from './fields/DateTimeRangePickerField';
import PermissionsSelectorField from './fields/PermissionsSelectorField';
import DateTimePickerField from './fields/DateTimePickerField';
import RelatedEventsField from './fields/RelatedEventsField';
import ModelArrayField from './fields/ModelArrayField';
import Heading from '../Heading';
import RecurrenceScheduleField from './fields/RecurrenceScheduleField';

const JSONEditor = React.memo(
  ({
    title,
    formData = {},
    schema,
    scrollIntoView = true,
    uiSchema = {},
    context = {},
    customFormats = {},
    onFormSubmit = () => {
      // do something onFormSubmit
    },
    onFormChange,
    onSecondaryAction,
    secondaryButtonText,
    customTransformErrors,
    customValidate,
    saveButtonText = 'Update properties',
    showButtons = true,
    flexEndButtons = false,
    initialEditMode = false,
    editorOnly = false,
    showNonEditForm = true,
    showEditButton = true,
    cancelCallback = () => null,
    ignoreKeys = [],
    submitDisabled = false,
    focusOnError = true,
    formRef,
    className,
    customErrorMessage,
  }) => {
    const editorRef = useRef();
    const [isEditor, setIsEditor] = useState(initialEditMode);

    const toggleEditor = () => {
      if (!editorOnly) setIsEditor(!isEditor);
    };

    const transformErrors = (errors) => {
      const errs = customTransformErrors ? customTransformErrors(errors) : errors;
      const uniqueErrors = [];
      errs.forEach((e) => {
        if (!uniqueErrors.find((ue) => ue.property === e.property && ue.message === e.message)) {
          uniqueErrors.push(e);
        }
      });
      return uniqueErrors;
    };

    const ajvOptionsOverrides = { verbose: true };
    const validator = customizeValidator({
      AjvClass: Ajv2019,
      ajvOptionsOverrides,
      ajvFormatOptions: false,
      customFormats,
    });

    const customValidator = (fData, errors) =>
      customValidate ? customValidate(fData, errors) : errors;

    const onCancel = () => {
      cancelCallback();
      if (!editorOnly) setIsEditor(false);
    };

    const onSubmit = ({ formData: updatedFormData }) => {
      if (!editorOnly) setIsEditor(false);
      const cleanedFormData = {};
      Object.entries(updatedFormData).forEach(([key, value]) => {
        const cleanedValue = typeof value === 'string' ? value.trim() : value;
        cleanedFormData[key] = cleanedValue;
      });
      onFormSubmit({
        formData: cleanedFormData,
      });
    };

    const onChange = ({ formData: updatedFormData }) => {
      if (onFormChange && typeof onFormChange === 'function') {
        onFormChange({ formData: updatedFormData });
      }
    };

    const renderFormData = (data) => {
      if (Array.isArray(data)) {
        return data.map((fd, i) => (
          <div
            key={i}
            className="form-data-item"
          >
            {renderFormData(fd)}{' '}
          </div>
        ));
      }

      if (typeof data === 'string' || typeof data === 'number') return data;

      return Object.entries(data || {}).map(([propkey, propvalue]) => {
        if (ignoreKeys.includes(propkey)) {
          return null;
        }

        return (
          <div
            key={propkey}
            className="row"
          >
            <div className="key">
              {schema.properties?.[propkey] ? schema.properties[propkey].title || propkey : propkey}
            </div>
            <div className="value">
              {typeof propvalue === 'string'
                ? JSON.stringify(propvalue).slice(1, -1)
                : JSON.stringify(propvalue)}
            </div>
          </div>
        );
      });
    };

    useEffect(() => {
      setIsEditor(editorOnly || initialEditMode);
      if (scrollIntoView && initialEditMode && editorRef.current) {
        editorRef.current.scrollIntoView();
      }
    }, [initialEditMode]);

    return (
      <div
        className="json-display-component"
        ref={editorRef}
      >
        {(title || showEditButton) && (
          <Heading
            contentLeft={
              <>
                <div className="title">{title}</div>
                {showEditButton ? (
                  <Button
                    onClick={toggleEditor}
                    activity="secondary"
                    slim
                  >
                    Edit
                  </Button>
                ) : null}
              </>
            }
          />
        )}

        {!isEditor ? (
          showNonEditForm && (
            <div className="json-properties">
              <div className="prop-table">
                {renderFormData(formData)}
                {Object.keys(formData || {}).length === 0 && <div className="empty">No value</div>}
              </div>
            </div>
          )
        ) : (
          <Form
            formData={formData}
            formContext={context}
            schema={schema}
            uiSchema={uiSchema}
            widgets={{
              TextWidget,
              TextareaWidget,
              SelectWidget,
            }}
            fields={{
              iconPicker: IconPickerField,
              dateRangePicker: DateTimeRangePickerField,
              dateTimePicker: DateTimePickerField,
              creatableField: CreatableField,
              inputWithTooltip: InputWithTooltipField,
              permissionsSelector: PermissionsSelectorField,
              relatedEvents: RelatedEventsField,
              recurrenceScheule: RecurrenceScheduleField,
              modelArrayField: ModelArrayField,
            }}
            customFormats={customFormats}
            onChange={onChange}
            onSubmit={onSubmit}
            transformErrors={transformErrors}
            customValidate={customValidator}
            showErrorList={false}
            ref={formRef}
            className={className}
            validator={validator}
            focusOnFirstError={focusOnError}
            noHtml5Validate
          >
            {customErrorMessage && (
              <div className="error--container">
                <div className="error--icon">
                  <Icon
                    icon={'error'}
                    size={'s'}
                  />
                </div>
                <div className="error--message">{customErrorMessage}</div>
              </div>
            )}
            {showButtons ? (
              <div
                style={{
                  justifyContent: `${flexEndButtons ? 'flex-end' : 'space-between'}`,
                  display: 'flex',
                }}
              >
                <Button
                  activity="secondary"
                  type="text"
                  onClick={onCancel}
                >
                  Cancel
                </Button>

                <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                  {onSecondaryAction && (
                    <Button
                      activity="primary"
                      type="button"
                      onClick={onSecondaryAction}
                      style={{ marginRight: '1rem' }}
                    >
                      {secondaryButtonText || 'Full Settings'}
                    </Button>
                  )}
                  <Button
                    style={{ margin: flexEndButtons ? '0 2rem' : 0 }}
                    activity="primary"
                    type="submit"
                    onClick={(e) => e.stopPropagation()}
                    disabled={submitDisabled}
                  >
                    {saveButtonText}
                  </Button>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </Form>
        )}
      </div>
    );
  },
  (prevProps, nextProps) =>
    /*
     * If not saving/updating form state in parent component (e.g. CreateAlgorithmModal),
     * and instead passing state which depends on redux state selectors,
     * make sure to memoize formData/schemas if they rely on any state that gets updates
     * via polling (i.e. components/variables/health, events, visualizations, files).
     *
     * Similarly, if the onFormSubmit and onFormChange handlers' use, rely on, or
     * are defined in components that are dependent on polled state, they or the parent
     * component should be memoized.
     */
    // eslint-disable-next-line implicit-arrow-linebreak
    JSON.stringify(prevProps.formData) === JSON.stringify(nextProps.formData) &&
    JSON.stringify(prevProps.schema) === JSON.stringify(nextProps.schema) &&
    JSON.stringify(prevProps.uiSchema) === JSON.stringify(nextProps.uiSchema) &&
    prevProps.onFormChange === nextProps.onFormChange &&
    prevProps.onFormSubmit === nextProps.onFormSubmit &&
    prevProps.customErrorMessage === nextProps.customErrorMessage &&
    prevProps.cancelCallback === nextProps.cancelCallback &&
    prevProps.customValidate === nextProps.customValidate
);

export default JSONEditor;
