import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Select, Icon, useTheme, colors } from '@iq/react-components';
import { isValid } from 'date-fns';

const isValidTime = (val, timeFormat) => {
  const date = new Date(0);
  switch (timeFormat) {
    case 'HH':
      date.setHours(val);
      return isValid(date);
    case 'mm':
      date.setMinutes(val);
      return isValid(date);
    case 'ss':
      date.setMinutes(val);
      return isValid(date);
    default:
      return false;
  }
};

const Picker = ({
  timeFormat,
  value,
  placeholder = '00',
  className,
  onSelect = () => null,
  onFocus = () => null,
  focus,
  min = 0,
  max = 59,
  step = 1,
  styles = {},
  inputRef = null,
  noOptionsMessage = 'Invalid time',
  ...extraProps
}) => {
  const theme = useTheme();
  // eslint-disable-next-line no-param-reassign
  inputRef = inputRef || useRef();
  const [selectedValue, setSelectedValue] = useState();

  const onKeyDown = (e) => {
    if (
      !(
        (parseInt(e.key, 10) >= 0 && e.key >= 0 && e.key <= 9) ||
        e.key === 'Backspace' ||
        e.key === 'Tab'
      )
    ) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (inputRef && inputRef.current) {
      if (focus) {
        inputRef.current.focus();
        document.addEventListener('keydown', onKeyDown, true);
      } else {
        inputRef.current.blur();
        document.removeEventListener('keydown', onKeyDown, true);
      }
    }
    return () => document.removeEventListener('keydown', onKeyDown, true);
  }, [focus]);

  const handleChange = (selection) => {
    onSelect(selection.value);
  };

  const buildNumbersList = (n, x, list = [], s = 1) => {
    if (n > x) return list;
    list.push(n);
    return buildNumbersList(n + s, x, list);
  };

  const options = useMemo(() => {
    const choices = buildNumbersList(min, max, [], step);
    return choices.map((num) => {
      let numString = num.toString();
      if (num < 10) {
        numString = `0${numString}`;
      }
      return { label: numString, value: numString };
    });
  }, [min, max, step]);

  const filterOption = ({ value: option }, inputValue) => option.startsWith(inputValue);

  const handleInputChange = (val) => {
    const isValidInput = val.length === 2 && isValidTime(val, timeFormat);
    const filteredOptions = options.filter((o) => o.value.startsWith(val));
    let selectionValue = '';

    if (timeFormat === 'HH' && parseInt(val, 10) > 23) {
      selectionValue = '23';
    } else if (!isValidInput && val.length === 1 && filteredOptions.length < 2) {
      selectionValue = `0${val}`;
    } else if (isValidInput) {
      selectionValue = val;
    }

    if (selectionValue !== '') {
      onSelect(selectionValue);
    }
  };

  useEffect(() => {
    const selection = value && options && options.filter((o) => o.value === value)[0];
    setSelectedValue(() => selection);
  }, [value, options]);

  const PickerStyles = {
    container: (base) => ({
      ...base,
      width: '1.5rem',
      ...(styles.container || {}),
    }),
    control: (base, state) => ({
      ...base,
      border: 'none',
      '&:hover': { border: 'none', borderColor: 'transparent' },
      '&:active': { border: 'none', borderColor: 'transparent' },
      minHeight: 'uset',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      justifyContent: 'flex-start',
      color: state.isFocused ? colors.StatusBlue : base.color,
      ...(styles.control || {}),
    }),
    singleValue: (base) => ({
      ...base,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginLeft: '0px',
      maxWidth: 'none',
      color: 'inherit',
      ...(styles.singleValue || {}),
    }),
    placeholder: (base) => ({
      ...base,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginLeft: '0px',
      maxWidth: 'none',
      ...(styles.placeholder || {}),
    }),
    input: () => ({
      position: 'absolute',
      input: {
        caretColor: 'transparent',
      },
    }),
    valueContainer: () => ({}),
    menu: (base) => ({
      ...base,
      width: 'fit-content',
      border: `1px solid ${theme === 'dark' ? colors.Grey70 : colors.Grey20}`,
      boxShadow: '0 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.15)',
      zIndex: '1000',
      marginTop: '22px',
      marginBottom: '22px',
      ...(styles.menu || {}),
    }),
    menuList: (base) => ({
      ...base,
      '&::-webkit-scrollbar': {
        '-webkit-appearance': 'none',
        width: '4px',
        height: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '4px',
        backgroundColor: theme === 'dark' ? 'rgba(255, 255, 255, .5)' : 'rgba(0, 0, 0, .5)',
        '-webkit-box-shadow':
          theme === 'dark' ? '0 0 1px rgba(0, 0, 0, .5)' : '0 0 1px rgba(255, 255, 255, .5)',
      },
    }),
  };

  return (
    <Select
      isMulti={false}
      options={options}
      value={selectedValue}
      placeholder={placeholder}
      className={className}
      onFocus={onFocus}
      onChange={handleChange}
      onInputChange={handleInputChange}
      hideSelectedOptions={false}
      noOptionsMessage={() => <div>{noOptionsMessage}</div>}
      blurInputOnSelect
      openMenuOnFocus
      filterOption={filterOption}
      components={{ IndicatorsContainer: () => null }}
      innerRef={inputRef}
      styles={PickerStyles}
      {...extraProps}
    />
  );
};

/*
 * Input/Output: time expression (xx:xx:xx)
 * Any timezone offset should be handled externally
 */
const TimePicker = ({
  value,
  withSeconds = false,
  onChange,
  styles = {},
  id,
  hourInputRef,
  secondsInputRef,
  onTabOrArrowBack,
  onTextComplete = () => null,
  // military = true, // TO DO : Add support for 12hr format?
}) => {
  const timeExp = /(\d\d:){1,2}(\d\d)/g;
  // eslint-disable-next-line no-param-reassign
  hourInputRef = hourInputRef || useRef();
  // eslint-disable-next-line no-param-reassign
  secondsInputRef = secondsInputRef || useRef();
  const minuteInputRef = useRef();

  const getTimeParts = (val) => {
    if (timeExp.test(val) && val.length <= 8) {
      return val.split(':');
    }
    return [null, null, null];
  };

  const initialFocus = {
    hours: false,
    minutes: false,
    seconds: false,
  };
  const [time, setTime] = useState();
  const [focus, setFocus] = useState({ ...initialFocus });

  useEffect(() => {
    if (value) {
      setTime(() => getTimeParts(value));
    }
  }, [value]);

  const handleSelection = (inputType, val) => {
    let newTime = [];
    if (inputType === 'h' && val !== time[0]) {
      newTime = [val, (time && time[1]) || '00', (time && time[2]) || '00'];
      setFocus((old) => ({ ...old, hours: false, minutes: true }));
    }
    if (inputType === 'm' && val !== time[1]) {
      newTime = [(time && time[0]) || '00', val, (time && time[2]) || '00'];
      setFocus((old) => ({ ...old, minutes: false, seconds: !!withSeconds }));
    }
    if (inputType === 's' && val !== time[2]) {
      newTime = [(time && time[0]) || '00', (time && time[1]) || '00', val];
      setFocus((old) => ({ ...old, seconds: false }));
    }

    if (newTime.length) {
      const newTimeString = newTime.join(':');
      onChange(newTimeString);
    }
  };

  const onKeyDown = (e) => {
    const arrowRight = e.key === 'ArrowRight';
    const shift = (e.key === 'Tab' && e.shiftKey) || e.key === 'ArrowLeft';
    if (shift && onTabOrArrowBack) {
      e.preventDefault();

      if (e.target === hourInputRef.current.select.inputRef) {
        onTabOrArrowBack();
      }

      if (e.target === minuteInputRef.current.select.inputRef) {
        hourInputRef.current.focus();
      }

      if (e.target === secondsInputRef.current.select.inputRef) {
        minuteInputRef.current.focus();
      }
    } else if (arrowRight) {
      if (e.target === hourInputRef.current.select.inputRef) {
        minuteInputRef.current.focus();
      }

      if (e.target === minuteInputRef.current.select.inputRef) {
        secondsInputRef.current.focus();
      }

      if (e.target === secondsInputRef.current.select.inputRef) {
        onTextComplete();
      }
    } else if (e.key === 'Tab') {
      if (e.target === secondsInputRef.current.select.inputRef) {
        e.preventDefault();
        onTextComplete();
      }
    }
  };

  const hasTime = time && !time.includes(null);
  return (
    <div
      className="time-picker"
      onKeyDown={onKeyDown}
      id={id}
    >
      <div className="time-picker__time">
        <div className="time-picker__hours">
          <Picker
            timeFormat="HH"
            value={time && time[0]}
            placeholder="hh"
            className="time-picker__hours-input"
            onSelect={(val) => handleSelection('h', val)}
            onBlur={() => setFocus((old) => ({ ...old, hours: false }))}
            max={23}
            focus={focus.hours}
            onFocus={() =>
              setFocus((old) => ({
                ...old,
                hours: true,
                minutes: false,
                seconds: false,
              }))
            }
            inputRef={hourInputRef}
            styles={{ ...(styles.hours || {}) }}
          />
        </div>
        <span>:</span>
        <div className="time-picker__minutes">
          <Picker
            timeFormat="mm"
            value={time && time[1]}
            placeholder={hasTime ? '00' : 'mm'}
            className="time-picker__minutes-input"
            onSelect={(val) => handleSelection('m', val)}
            onBlur={() => setFocus((old) => ({ ...old, minutes: false }))}
            max={59}
            focus={focus.minutes}
            onFocus={() =>
              setFocus((old) => ({
                ...old,
                minutes: true,
                hours: false,
                seconds: false,
              }))
            }
            inputRef={minuteInputRef}
            styles={{
              container: { width: hasTime ? '1.5rem' : '2.1rem' },
              ...(styles.minutes || {}),
            }}
          />
        </div>
        {withSeconds && (
          <>
            <span>:</span>
            <div className="time-picker__seconds">
              <Picker
                timeFormat="ss"
                value={time && time[2]}
                placeholder="ss"
                className="time-picker__seconds-input"
                onSelect={(val) => handleSelection('s', val)}
                onBlur={() => setFocus((old) => ({ ...old, seconds: false }))}
                max={59}
                focus={focus.seconds}
                onFocus={() =>
                  setFocus((old) => ({
                    ...old,
                    seconds: true,
                    hours: false,
                    minutes: false,
                  }))
                }
                inputRef={secondsInputRef}
                styles={{ ...(styles.seconds || {}) }}
              />
            </div>
          </>
        )}
      </div>
      <Icon
        className="time-picker__button"
        icon="abb-time"
        onClick={() => setFocus((old) => ({ ...old, hours: true }))}
      />
    </div>
  );
};

export default TimePicker;
