import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { Icon, Button } from '@iq/react-components';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { DatePicker, TimePicker } from '../DateTimePicker';
import {
  setPollingDateRange,
  getPollingDateRange,
  getTimezone,
  periods,
  getRangeFromLabel,
  getRangeFromPartial,
} from '../../bundles/application';
import {
  utcToSite,
  siteToUtc,
  datetimeString,
  getSiteTime,
  zonedDateAndTimeToUtc,
} from '../../datetimeUtils';

const APPLY_BUTTON_ID = 'date-range-apply';

const SelectPollingDateRange = ({ history, panelId, pickerId, enableGlobalDates = true }) => {
  const dispatch = useDispatch();
  const internalId = pickerId || panelId;
  const externalDateRange = useSelector((state) => getPollingDateRange(state, panelId));
  const internalDateRange = useSelector((state) => getPollingDateRange(state, internalId));
  const timezone = useSelector(getTimezone);
  const [open, setOpen] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [global, setGlobal] = useState(false);
  const [localDateRange, setLocalDateRange] = useState(externalDateRange);
  const [displayDate, setDisplayDate] = useState(externalDateRange);

  const ref = useRef(null);
  const dateInputRef = useRef(null);
  const startTimeSecondsRef = useRef(null);
  const startTimeHoursRef = useRef(null);
  const endTimeHoursRef = useRef(null);
  const localDateRangeRef = useRef(null);

  const START_DATE = `polling-range-start_${internalId}`;
  const START_TIME_SECONDS = `polling-range-start-time-s_${internalId}`;
  const START_TIME_HOURS = `polling-range-start-time-h_${internalId}`;
  const END_DATE = `polling-range-end_${internalId}`;
  const END_TIME_HOURS = `polling-range-end-time-h_${internalId}`;

  const updateLocalDateRange = (range) => {
    localDateRangeRef.current = { ...range };
    setLocalDateRange(() => range);
  };

  const validateDates = (defaultTo = 'start') => {
    const { startDate, endDate } = localDateRangeRef.current || {};
    if (startDate > endDate) {
      const date = defaultTo === 'start' ? startDate : endDate;
      updateLocalDateRange({
        startDate: date,
        endDate: date,
      });
    }
  };

  const handleClickOutside = (event) => {
    if (!ref.current) return;
    if (
      ref.current.contains(event.target) &&
      !(
        event.target.classList.contains('DateInput_input') ||
        event.target.id.contains('polling-range') ||
        event.target.classList.contains('css-12a3bhy-singleValue')
      )
    ) {
      validateDates();
    }
    if (
      !ref.current.contains(event.target) &&
      !event.target.classList.contains('CalendarDay') &&
      !event.target.classList.contains('css-fn1hnl-option')
    ) {
      validateDates();
      setOpen(false);
    }
  };

  const handleOpen = (e) => {
    if (e.target === ref.current && (e.key === ' ' || e.code === 'Space')) {
      setOpen(true);
    }
  };

  useEffect(() => {
    const unlisten = history.listen(() => {
      setOpen(false);
    });
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('keyup', handleOpen);
    document.querySelector('#global-modal-container').addEventListener('click', handleClickOutside);
    document.querySelector('#global-modal-container').addEventListener('keyup', handleOpen);
    return () => {
      unlisten();
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keyup', handleOpen);
      document
        .querySelector('#global-modal-container')
        .addEventListener('click', handleClickOutside);
      document.querySelector('#global-modal-container').addEventListener('keyup', handleOpen);
    };
  }, []);

  useEffect(() => {
    updateLocalDateRange(internalDateRange);
    setDisplayDate(() => internalDateRange);
  }, [internalDateRange]);

  useEffect(() => {
    updateLocalDateRange(externalDateRange);
    setDisplayDate(() => externalDateRange);
  }, [externalDateRange]);

  const setFocus = (id, selectionStart = 0, selectionEnd = 4) => {
    const input = document.getElementById(id);
    if (id === START_TIME_SECONDS) {
      startTimeSecondsRef.current.focus();
    } else if (id === START_TIME_HOURS) {
      startTimeHoursRef.current.focus();
    } else if (id === END_TIME_HOURS) {
      endTimeHoursRef.current.focus();
    } else if (input) {
      input.focus();
    }
    if (input?.setSelectionRange) {
      input.setSelectionRange(selectionStart, selectionEnd);
    }
  };

  const handleFieldChange = (currentType, nextId, selectionStart = 0, selectionEnd = 4) => {
    validateDates(currentType);
    if (nextId) {
      setFocus(nextId, selectionStart, selectionEnd);
    }
  };

  const onExit = (e) => {
    if (e.key === 'Escape') {
      setOpen(false);
      updateLocalDateRange(externalDateRange);
    }
  };

  useEffect(() => {
    if (open && canSave) {
      document.addEventListener('keyup', onExit);
    } else {
      document.removeEventListener('keyup', onExit);
    }
    return () => {
      document.removeEventListener('keyup', onExit);
    };
  }, [open, canSave]);

  const handleSubmitDateRange = () => {
    // safe guard against invalid range
    if (localDateRangeRef.current.startDate <= localDateRangeRef.current.endDate) {
      const range = getRangeFromPartial(localDateRangeRef.current);
      if (global) {
        dispatch(setPollingDateRange(range));
      } else {
        dispatch(setPollingDateRange(range, internalId));
      }

      setOpen(false);
    }
  };

  const handleSubmitRelativePeriod = (period) => {
    const range = getRangeFromLabel(period);

    if (global) {
      dispatch(setPollingDateRange(range));
    } else {
      dispatch(setPollingDateRange(range, internalId));
    }

    setOpen(false);
  };

  const onSave = (e) => {
    if (e.key === 'Enter' && e.target.id === APPLY_BUTTON_ID) {
      handleSubmitDateRange();
    }
  };

  useEffect(() => {
    if (canSave) {
      document.addEventListener('keyup', onSave);
    } else {
      document.removeEventListener('keyup', onSave);
    }
    return () => {
      document.removeEventListener('keyup', onSave);
    };
  }, [canSave]);

  const classes = classnames('select-period-component', { open });

  const setTime = (zonedTime, type) => {
    const localDateTimes = { ...localDateRangeRef.current };
    localDateTimes[type] = zonedDateAndTimeToUtc(
      localDateTimes[type],
      zonedTime,
      timezone
    ).getTime();

    updateLocalDateRange({
      startDate: localDateTimes.startDate,
      endDate: localDateTimes.endDate,
    });
  };

  const setDate = (zonedTimestamp, type) => {
    const updateTs = siteToUtc(new Date(zonedTimestamp), timezone).getTime();

    if (type === 'startDate') {
      updateLocalDateRange({
        startDate: updateTs,
        endDate: localDateRangeRef.current.endDate,
      });
    } else {
      updateLocalDateRange({
        startDate: localDateRangeRef.current.startDate,
        endDate: updateTs,
      });
    }
  };

  const format = (timestamp, withTz = false) =>
    timestamp ? datetimeString(utcToSite(timestamp, timezone), timezone, false, withTz) : '-';

  const startTime = localDateRange.startDate
    ? getSiteTime(localDateRange.startDate, timezone)
    : '00:00:00';
  const endTime = localDateRange.endDate
    ? getSiteTime(localDateRange.endDate, timezone)
    : '00:00:00';

  const pickerSpaceLeft = dateInputRef.current?.getBoundingClientRect().left || 0;
  const pickerClass = `absolute-time-picker ${pickerSpaceLeft < 330 ? 'calendar-right' : ''}`;
  const checkboxIcon = `check-box${global ? '' : '-outline-blank'}`;

  return localDateRange ? (
    <div
      className={classes}
      tabIndex={0}
      ref={ref}
    >
      <div
        className="header"
        onClick={() => setOpen(!open)}
      >
        <div className="label">
          {displayDate.label || null}
          {!displayDate.label && (
            <div>
              {format(displayDate.startDate)}
              <span> - </span>
              {format(displayDate.endDate)}
            </div>
          )}
        </div>
        <Icon
          icon="abb-time-and-date"
          size="s"
        />
      </div>
      <div className="content">
        <div className="actions">
          {enableGlobalDates && (
            <div className="actions-checkbox">
              <Icon
                style={{ cursor: 'pointer', color: '#3366ff' }}
                icon={checkboxIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  setGlobal(!global);
                }}
              />
              <p>Apply to entire site</p>
            </div>
          )}

          {!!localDateRange.startDate && (
            <div className="submit">
              <Button
                activity="primary"
                slim
                type="button"
                onClick={handleSubmitDateRange}
                id={APPLY_BUTTON_ID}
                disabled={localDateRange.startDate > localDateRange.endDate}
              >
                Apply
              </Button>
            </div>
          )}
        </div>
        <hr />
        <div className="absolute">
          <div className="title">Absolute range</div>
          <div
            className={pickerClass}
            ref={dateInputRef}
          >
            <label className="absolute-label">From</label>
            <div className="datetime-row">
              <DatePicker
                id={START_DATE}
                value={utcToSite(localDateRange.startDate, timezone)}
                onChange={(date) => setDate(date, 'startDate')}
                onCalendarOpen={(isOpen) => setCanSave(!isOpen)}
                onTabOrArrowBack={() => handleFieldChange('start', null)}
                onTextComplete={() => handleFieldChange('start', START_TIME_HOURS)}
              />
              <TimePicker
                withSeconds
                value={startTime}
                hourInputRef={startTimeHoursRef}
                secondsInputRef={startTimeSecondsRef}
                onChange={(time) => setTime(time, 'startDate')}
                onTabOrArrowBack={() => handleFieldChange('start', START_DATE, 8, 10)}
                onTextComplete={() => handleFieldChange('start', END_DATE)}
              />
            </div>
            <label className="absolute-label">To</label>
            <div className="datetime-row">
              <DatePicker
                id={END_DATE}
                value={utcToSite(localDateRange.endDate, timezone)}
                onChange={(date) => setDate(date, 'endDate')}
                onCalendarOpen={(isOpen) => setCanSave(!isOpen)}
                onTabOrArrowBack={() => handleFieldChange('end', START_TIME_SECONDS)}
                onTextComplete={() => handleFieldChange('end', END_TIME_HOURS)}
              />
              <TimePicker
                withSeconds
                value={endTime}
                hourInputRef={endTimeHoursRef}
                onChange={(time) => setTime(time, 'endDate')}
                onTabOrArrowBack={() => handleFieldChange('end', END_DATE, 8, 10)}
                onTextComplete={() => handleFieldChange('end', null)}
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="relative">
          <div className="title">Relative range</div>
          <div className="fixed-periods choices">
            {periods.map(({ label }) => (
              <div
                className={`choice ${localDateRange.label === label ? 'selected' : ''}`}
                key={label}
                role="button"
                onClick={() => handleSubmitRelativePeriod(label)}
              >
                {label}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default withRouter(SelectPollingDateRange);
